import React from "react";
// import SignUp from "../Signup";
import "../PropertyPage.css";
import { Link } from "react-router-dom";
import Head from "../../Component/Head";
import { axios_createInvestorProperty } from "../../utills/property-routes";

class DetailPageTitle extends React.Component {
  modalClickHandlerBtn = (clickType) => {
    const InvestorsPropertyData = {
      ...this.props.property,
      user_id: this.props.user.id,
      latitude: this.props.property.lat,
      longitude: this.props.property.lng,
      token: localStorage.getItem("userToken"),
    };
    axios_createInvestorProperty(
      InvestorsPropertyData,
      this.CreatePropertyCallback
    );
  };

  CreatePropertyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        if (response.data.meta === "Investors") {
          setTimeout(() => {
            this.props.history.push('/propertiesinvestor')
          }, 0);
        }
      }
    }
  };

  render() {
    return (
      <>
        <Head title={`${this.props.property.property_address} | Exuity`} />
        <div className="DetailPageTitle">
          <div className="DetailPageTitleGroup">
            <div className="detail-cta-group hidden">
              <span className="detail-cta">USA</span>
              <span className="detail-cta">Georgia</span>
              <span className="detail-cta">Atlanta</span>
              <span className="detail-cta">Single-Family</span>
              <span className="detail-cta">Owner-Occupied</span>
            </div>
            <div className="title-name">
              <h2>{this.props.property.property_address}</h2>
              <span className="prop-address">
                {this.props.property.property_address}
              </span>
            </div>
            <div className="home-quantity-cta">

            </div>
            <div className="buy-shares-btn">
              {this.props.isAuthenticated &&
                this.props.property.owner_id === this.props.user.id ? null : (
                <Link
                  to={{
                    pathname: `${this.props.isAuthenticated ? "" : "/signup"}`,
                    state: {
                      cardType: "Investor",
                      address: this.props.property,
                      lat: this.props.property.lat,
                      lng: this.props.property.lng,
                    },
                  }}
                  onClick={(e) =>
                    this.props.isAuthenticated &&
                    this.modalClickHandlerBtn("Investor")
                  }
                >
                  <button
                    type="button"
                  >
                    Buy Shares
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DetailPageTitle;
