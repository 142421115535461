import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import moment from "moment";

import { axios_getIssuanceOwnerList } from "../../utills/issuance-routes";
import { get_image_full_url } from "../../../src/utills/helper";

export default function TableOwnerIssuances() {
  const [tableData, setTableData] = useState([]);
  const [redirect, setredirect] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPageCount, setTotalCount] = useState();

  const gettIssuanceOwnerCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        if (response.data.data.auction !== null) {
          setTotalCount(response.data.data.count);
          setTableData(response.data.data.rows);
        }
      }
    }
    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "auction.property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={get_image_full_url(
                  row &&
                    row.row.original.auction.property.properties_images &&
                    row.row.original.auction.property.properties_images[0] &&
                    row.row.original.auction.property.properties_images[0]["image"]
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        }
      },
      {
        Header: "Effective Date",
        accessor: "created_at",
        Cell: (row) => {
          return (
            <>{moment(row.value).isValid() ? moment(row.value).format("MMM DD, YYYY") : "-"}</>
          );
        }
      },
      {
        Header: "Amount Raised",
        accessor: "amount",
        Cell: (row) => {
          return `$${Number(row.value).toLocaleString("en-US")}`;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          if (row.value === "Complete") {
            return <span className="tag--active">Complete</span>;
          } else {
            return <span className="tag--closed">Fail</span>;
          }
        }
      },
      {
        Header: "Yield",
        accessor: "yield",
        Cell: (row) => {
          return `${(row.value * 100).toFixed(2)}%`;
        }
      },
      {
        Header: "Term",
        accessor: "contract_length",
        Cell: (row) => {
          return `${row.value} Years`;
        }
      }
    ],
    []
  );

  const data = tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = useTable(
    {
      manualPagination: true,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: totalPageCount ? Math.ceil(totalPageCount / 10) : null
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    axios_getIssuanceOwnerList(pageIndex, pageSize, gettIssuanceOwnerCallback);
  }, [pageIndex, pageSize]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div>
        <div className="issuances-table">
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-responsive" responsive="lg">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page && page.length > 0 ? (
                  <>
                    {page &&
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() =>
                              setredirect("/issuancesowner-details/" + row.original.code)
                            }>
                            {row.cells.map((cell) => {
                              return (
                                <td className="dash-tbl-col" {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <td colSpan="12" className="date-col">
                        <div className="text-center pt-5">
                          <div className="spinner-grow text-warning" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <p>Loading. Please wait...</p>
                        </div>
                      </td>
                    ) : (
                      <tr className="no-hover">
                        <td colSpan="6" className="date-col">
                          <div className="table-empty-state">
                            <div className="empty-state-img">
                              <img
                                src={require("../../Images/empty-state-icon.svg")}
                                alt="empty state img"
                              />
                              <p>
                                No equity issued yet <br /> Reason: No
                                <Link to="/auctions"> auctions launched</Link>
                              </p>
                              <div className="createauction-btn"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            {totalPageCount > 10 && (
              <div className="region-pagination">
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}>
                  {"<<"}
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}>
                  Previous
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}>
                  Next
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}>
                  {">>"}
                </button>{" "}
                <span className="pagination-text">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
