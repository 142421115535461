import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import moment from "moment";

import { axios_getOwnerProperty } from "../../utills/property-routes";
import { str_into_num } from "../../utills/string-into-number";
import { get_image_full_url } from "../../utills/helper";

import "../../components/properties/properties.css";

export default function OwnerPropertyTable(props) {
  const [redirect, setredirect] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageCount, setTotalCount] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getOwnerProperty(currentPage, OwnerPropertyCallback);
  }, [currentPage]);

  const OwnerPropertyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCount(response.data.data.count);
        setTableData(response.data.data.rows);
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={get_image_full_url(
                  row.row.original.properties_images &&
                  row.row.original.properties_images[0] &&
                  row.row.original.properties_images[0].image
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          if (row.value === "Active") {
            return <span className="tag--active">{row.value}</span>;
          } else {
            return (
              <span className="tag--inreview" style={{ whiteSpace: "nowrap" }}>
                Under Review
              </span>
            );
          }
        }
      },
      {
        Header: "Est. Value",
        accessor: "estimated_property_value",
        Cell: (row) => {
          if (row.value !== null) {
            return `$${str_into_num(row.value)}`;
          } else {
            return "-";
          }
        }
      },
      {
        Header: "Auction Launched ?",
        accessor: "auction_launched"
      },
      {
        Header: "Date Saved",
        accessor: "createdAt",
        Cell: (row) => {
          return (
            <>{moment(row.value).isValid() ? moment(row.value).format("MMM DD, YYYY") : "-"}</>
          );
        }
      }
    ],
    []
  );

  const data = tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = useTable(
    {
      manualPagination: true,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: totalPageCount ? Math.ceil(totalPageCount / 10) : null
    },
    usePagination
  );

  const { pageIndex } = state;

  useEffect(() => {
    if (setCurrentPage !== undefined) {
      setCurrentPage(pageIndex);
    }
  }, [pageIndex]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div>
        <div className="paymentowed-table paymentowed-table-2">
          {isLoading === false && tableData.length > 0 ? (
            <>
              <table {...getTableProps()} className="table table-responsive" responsive="lg">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  <>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() => setredirect("/property-details/" + row.original.code)}>
                          {row.cells.map((cell) => {
                            return (
                              <td className="dash-tbl-col" {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                </tbody>
              </table>
              {totalPageCount > 10 && (
                <div className="region-pagination">
                  <button
                    className="btn btn-primary"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}>
                    {"<<"}
                  </button>{" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}>
                    Previous
                  </button>{" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}>
                    Next
                  </button>{" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}>
                    {">>"}
                  </button>{" "}
                  <span className="pagination-text">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                </div>
              )}
            </>
          ) : isLoading === false && tableData.length === 0 ? (
            <>
              <table {...getTableProps()} className="table table-responsive" responsive="lg">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <div className="table-empty-state">
                        <div className="empty-state-img">
                          <img
                            src={require("../../Images/empty-state-icon.svg")}
                            alt="empty state img"
                          />
                          <p>You haven't added any property</p>
                        </div>
                        <div className="addPropert-btn">
                          <NavLink to="/properties/create">
                            <button type="button">
                              <span>
                                <svg
                                  className="btn-plusIcon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12px"
                                  height="12px">
                                  <path
                                    opacity="0.749"
                                    fill="rgb(255, 255, 255)"
                                    d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"
                                  />
                                </svg>
                              </span>
                              Add property
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
