import { toast } from "react-toastify";
import axios from "./axios-server";

export const createTokenLink = () => {
  return axios.post(
    `/plaid/create-link-token`,
    {},
    {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const exchangePublicToken = (public_token, accountId) => {
  return axios.post(
    `/plaid/exchange-public-token?publicToken=${public_token}&accountId=${accountId}`,
    {},
    {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const getUserBalance = () => {
  return axios.get(
    `/plaid/processor/balance/get`,
    {},
    {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const getUserAccounts = () => {
  return axios.get(
    `/plaid/processor/accounts/get`,
    {},
    {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const connectAccontType = function (account_id, setLoading) {
  return axios
    .post(
      `/user/connect-account`,
      {
        account_type_id: account_id,
      },
      {
        headers: {
          "x-auth-token": localStorage.getItem("userToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      // console.log(err);
    });
};

export const widthDrawAmount = function (data,setLoading) {
  return axios
    .post(`/withdraw`, data, {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false)
        },2000)
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      // console.log(err);
    });
};

export const removeAccount = function (data,getuserData) {
  return axios
    .post(`/user/remove-account`, data, {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res) {
        getuserData()
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      // console.log(err);
    });
};
