import React, { useEffect, useState } from "react";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import { useParams } from "react-router-dom";
import { axios_getPaymentOwedOwnerByCode } from "../../utills/paymentOwed-routes";
import { str_into_float_num, str_into_num } from "../../utills/string-into-number";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function PayamentOwedDetails() {
  const history = useHistory();
  const { code } = useParams();
  const [paymentOwedData, setPaymentOwedData] = useState();

  useEffect(() => {
    const getIssuanceCallback = (response) => {
      if (response.status === 200) {
        if (response.data.status === true) {
          setPaymentOwedData(response.data.data);
        }
      }
      if (response.data.data === null) {
        toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
        history.push("/paymentowedowner");
      }
    };
    axios_getPaymentOwedOwnerByCode(code, getIssuanceCallback);
  }, [code, history]);

  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Payment Owed Detail</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {paymentOwedData != null ? (
                  <div>
                    <ul>
                      <li>
                        <b>Home Value: </b> ${str_into_num(paymentOwedData.home_value)}
                      </li>
                      <li>
                        <b>Investor Equity: </b>{" "}
                        {str_into_float_num(paymentOwedData.investor_equity * 100)}%
                      </li>
                      <li>
                        <b>Payment Owed Year End:</b> $
                        {str_into_num(paymentOwedData.payment_owed_year_end)}
                      </li>
                      <li>
                        <b>Amount Raising:</b> ${str_into_num(paymentOwedData.issuance.amount)}
                      </li>
                      <li>
                        <b>Due Date:</b>{" "}
                        {moment(paymentOwedData.created_at).isValid()
                          ? moment(paymentOwedData.created_at).add(1, "y").format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Property Address:</b>{" "}
                        {paymentOwedData.issuance.auction.property.property_address}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
