import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_addAuction = (property_id,amount_raising, max_yield, contract_length, start_date) => {
    return axios.post('/auction/add', { property_id,amount_raising, max_yield, contract_length, start_date }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const axios_updateAuction = (property_id,amount_raising, max_yield, contract_length, start_date) => {
    return axios.post('/auction/update', { property_id,amount_raising, max_yield, contract_length, start_date }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const axios_deleteAuction = (id) => {
    return axios.post('/auction/delete', { id }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const axios_loadAuction = (id) => {
    return axios.get('/auction/view/'+id, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const axios_loadAuctions = (page) => {
    return axios.get('/auction/list/'+page+'/10', {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const axios_addBid = (auction_id, amount_offered, cent_on_dollar) => {
    return axios.post('/auction/bid', { auction_id, amount_offered, yield:cent_on_dollar/100 }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const axios_auctionsIbided=(page, limit,callback)=>{
    axios
    .get(`/auction/auctionsIbided/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}