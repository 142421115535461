import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { axios_createTaxInfo } from '../../utills/taxInfo-routes';

export default function IndividualTaxForm(props) {

    const [isDisabledField, setIsDisabledField] = useState(props.isDisabledField);
    const [taxInfo, setTaxInfo] = useState();

    useEffect(() => {
        setTaxInfo(props.taxInfo)
        if(props.taxInfo){
            setIsDisabledField(true)
        }
    }, [props.taxInfo])


    const createTaxCallback = (response) => {
        if (response.status === 200) {
            if (response.data.status === true) {
                setIsDisabledField(true)
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT })
            } else {
                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT })
            }
        }
    };

    const TaxCreateSchema = () => {

        return Yup.object().shape({
            individual_name: Yup.string()
                .required("Please provide name")
                .min(2, "Please provide valid name")
                .max(50, "Name must be at most 50 characters")
                .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please provide valid name"),
            ssn: Yup.string()
                .required("Please provide SSN")
                .min(2, "Please provide valid SSN")
                .max(50, "SSN must be at most 50 characters"),
            street_address: Yup.string()
                .required("Please provide street address")
                .min(2, "Please provide valid street address")
                .max(50, "Street address must be at most 50 characters"),
            city: Yup.string()
                .required("Please Provide City name")
                .min(2, "Please provide valid City name")
                .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please provide valid City name"),
            state: Yup.string()
                .required("Please provide State name")
                .min(2, "Please provide valid State name")
                .max(50, "State name must be at most 50 characters")
                .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please provide valid State name"),
            zip: Yup.string()
                .matches(/^[0-9]+$/, "Please enter a valid Zip")
                .required("Please provide Zip")
                .min(5, "Zip code must have at least 5 characters")
                .max(6, "Zip code must have at most 6 characters")
        });

    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    tax_type: "individual",
                    individual_name: taxInfo && taxInfo.individual_name,
                    entity_name: taxInfo && taxInfo.entity_name,
                    ssn: taxInfo && taxInfo.ssn,
                    ein: taxInfo && taxInfo.ein,
                    street_address: taxInfo && taxInfo.street_address,
                    city: taxInfo && taxInfo.city,
                    state: taxInfo && taxInfo.state,
                    zip: taxInfo && taxInfo.zip
                }}
                validationSchema={TaxCreateSchema}
                onSubmit={(values) => {
                    axios_createTaxInfo(values, createTaxCallback);
                }}>
                {(props) => (
                    <Form>
                        <ul className="AddProperty-inputs">


                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <Field
                                        type="text"
                                        name="individual_name"
                                        className={`form-control ${props.touched.individual_name &&
                                            props.errors.individual_name
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        placeholder="Name"
                                        disabled={isDisabledField}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="individual_name"
                                        className="invalid-feedback"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="form-group">
                                    <label htmlFor="">Social Security Number</label>
                                    <Field
                                        type="text"
                                        name="ssn"
                                        className={`form-control ${props.touched.ssn && props.errors.ssn ? "is-invalid" : ""
                                            }`}
                                        placeholder="Social Security Number"
                                        disabled={isDisabledField}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="ssn"
                                        className="invalid-feedback"
                                    />
                                </div>
                            </li>




                            <>
                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Street Address</label>
                                        <Field
                                            type="text"
                                            name="street_address"
                                            className={`form-control ${props.touched.street_address &&
                                                props.errors.street_address
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="Street Address"
                                            disabled={isDisabledField}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="street_address"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">City</label>
                                        <Field
                                            type="text"
                                            name="city"
                                            className={`form-control ${props.touched.city && props.errors.city
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="City"
                                            disabled={isDisabledField}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="city"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">State</label>
                                        <Field
                                            type="text"
                                            name="state"
                                            className={`form-control ${props.touched.state && props.errors.state
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            placeholder="Street Address"
                                            disabled={isDisabledField}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="state"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </li>

                                <li>
                                    <div className="form-group">
                                        <label htmlFor="">Zip</label>
                                        <Field
                                            type="text"
                                            name="zip"
                                            className={`form-control ${props.touched.zip && props.errors.zip ? "is-invalid" : ""
                                                }`}
                                            placeholder="Zip"
                                            disabled={isDisabledField}
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="zip"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </li>
                                <div className="Ap-submitted-btn pt-4 text-left">
                                    <button
                                        type="button"
                                        className="mr-3"
                                        onClick={() => setIsDisabledField(!isDisabledField)}>
                                        {isDisabledField ? "Edit Tax Info" : "Cancel"}
                                    </button>
                                    <button
                                        type="submit"
                                        className={isDisabledField ? "d-none" : ""}>
                                        Save Changes
                                    </button>
                                </div>
                            </>

                        </ul>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
