import API_URL from "./api";

const URL =
  API_URL === "http://staging.vessel.us:4000/api"
    ? "https://api.exuity.com"
    : "http://localhost:4000";

export const PROPERTY_DEFAULT_IMG = `https://api.exuity.com/property/default.png`;

export const imageChecker = (url) => {
  if (
    url === "https://localhost:4000/property/default.png" || url === "" ||
    url ===   "https://api.exuity.com/property/default.png" || url === null 
    
  ) {
    return PROPERTY_DEFAULT_IMG;
  } else {
    return `${URL}/${url}`;
  }
};

export const getImagesArray = (images) => {
  if(images==='[]' || images===null || images === undefined || images === 'undefined' || images === 'null'){
    images='';
  }
  if(images.length>0){
    if(typeof images === 'object' || typeof images === 'array'){
      images = images.join(',');
    }
    images = images.replace('"',"");
    images = images ? images && images.split && images.split(",") : [];
  }else{
    images = [];
  }

 return  images.filter(function(value, index){ 
    if(value!=='[]' && value!==null && value !== undefined && value !== 'undefined' && value !== 'null' && value!==""){
      return value;
    }
});
}