import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_getPaymentMadeList=( page, limit, callback)=>{
    axios
    .get(`payment-made/list/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getPaymentMadeDetailsByCode=(code, callback)=>{
    return axios
    .get(`/payment-made/getPaymentMadeDetailsByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    )  
}
