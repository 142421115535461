import axios from 'axios';
import { toast } from 'react-toastify';
import API_URL from '../config/api';

const instance=axios.create({
    baseURL:API_URL
    
})

if (localStorage.userToken) {
    instance.defaults.headers.common["x-auth-token"] = localStorage.userToken;
  } else {
    delete instance.defaults.headers.common["x-auth-token"];
  }


  instance.interceptors.response.use((response)=>{
    if(response.status===200)
    {

    }
    return response;
  },
  (error)=>{
    if(error.response.data.meta==="invalid_token" && error.response.status===401 ){
      toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      localStorage.clear()
      window.location.reload()
    } 
return Promise.reject(error)
}
)
export default instance;
