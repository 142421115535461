import React from "react";
import Sidenav from "../Sidenav";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import AuctionCard from "./AuctionCard";
import SubmitBidCard from "./SubmitBidCard";
import ExistingBidCard from "./ExistingBidCard";
import { axios_loadAuction } from "../../utills/auction-routes";
import Head from "../../Component/Head";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
class Auction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      auction: {},
      found: 0,
      time: {},
      seconds: 5,
      redirect: null,
    };
    this.timer = 0;
  }
  componentDidMount() {
    this.setState({ found: 1 });
    axios_loadAuction(this.props.match.params.id)
      .then((res) => {
        if (res.data.status) {
            this.setState({ auction: res.data.data });
            this.setState({ found: 2 });
            this.startCountdown();
            document.title = `${res.data.data.property.property_address} | Exuity`;
          } else {
            this.setState({ found: 0 });
            toast.error(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            this.setState({ redirect: "/auctions" });
          }
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.", err.response.data.message);
        toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
        this.setState({ found: 0 });
        this.setState({ redirect: "/auctions" });
      });
  }
  startCountdown() {
    var date_to = new Date(this.state.auction.end_date);
    var diff = date_to - new Date();
    if (diff > 0) {
      var sec = diff / 1000;
      this.setState({ seconds: sec });
      let timeLeftVar = this.secondsToTime(sec);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }
  }
  openProperty() {
    this.setState({
      isVisible: true,
    });
  }
  secondsToTime(secs) {
    let days = Math.floor(secs / (60 * 60 * 24));

    let divisor_for_hours = secs % (60 * 60 * 24);

    let hours = Math.floor(divisor_for_hours / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      var t = this;
      this.timer = setInterval(function () {
        t.countDown();
      }, 1000);
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }


    return (
      <div className="Dashboard">
        <Head
          title={`Home equity auction | ${this.state &&
            this.state.auction &&
            this.state.auction.property &&
            this.state.auction.property.property_address
            } | Exuity`}
        />
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Auctions</h4>
            </div>
            <Sidenav />
          </div>
          <div className="row">
            <div className="auction-navbar">
              <Sidebar />
            </div>
            <div className="DashContent pt-0 pt-lg-5">
              <div className="AuctionPage">
                {this.state.found === 2 ? (
                  <div>
                    <div className="auction-detailpage-row-1">
                      <AuctionCard auction={this.state.auction} />
                    </div>

                    <div className="auction-detailpage-row-2">
                      <SubmitBidCard
                        timer={this.state.seconds}
                        auction={this.state.auction}
                      />

                      <ExistingBidCard auction={this.state.auction} />
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.found === 0 ? (
                      <div className="HeroGrid">
                        <p>Auction not found</p>
                      </div>
                    ) : (
                      <div className="HeroGrid text-center pt-5">
                        <div
                          className="spinner-grow text-warning"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p>Loading. Please wait...</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Auction;
