import React from 'react'
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setToast } from '../../store/actions/toast';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function PageNotFound() {
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(()=>{

    toast.error("Sorry; we can't find that page.",{position: toast.POSITION.TOP_RIGHT})
    history.push("/")
  },[dispatch, history])

  return (
    <div>
      <h4>Page not found!</h4>
    </div>
  )
}
