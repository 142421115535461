import React from "react";
// import { useLocation } from "react-router-dom";
import "./Form.css";
import PropertyRegionStreetView from "../Component/property-region-street-view/PropertyRegionStreetView";
import PropertyRegionMap from "../components/PropertyRegionMap";

export default function PropertyCardSignup(props) {
  // const location = useLocation();

  // const [cardType, setCardType] = useState("");

  // useEffect(() => {
  //   if (typeof location.state != "undefined") {
  //     setCardType(location && location.state.cardType);
  //   }
  // }, [location]);

  return (
    <>
      {/* {typeof location.state != "undefined" ? (
        <div className="container">
          <div className="row align-items-center property-card-signup-wrapper">
            <div className="col-sm-5">
              {location.state.addressSignupData.searchTab === "property" ? (
                <PropertyRegionStreetView
                  lat={location.state.addressSignupData.lat}
                  lng={location.state.addressSignupData.lng}
                  height={"85px"}
                />
              ) : (
                <PropertyRegionMap
                  lat={location.state.addressSignupData.lat}
                  lng={location.state.addressSignupData.lng}
                  height={"85px"}
                />
              )}
            </div>
            <div className="col-sm-7">
              {cardType === "Owner" ? (
                <p>
                  To claim{" "}
                  <span className="font-weight-bold">
                    {location.state.addressSignupData.address &&
                      location.state.addressSignupData.address.substr(
                        0,
                        location.state.addressSignupData.address.indexOf(",")
                      )}
                  </span>
                  . as yours, step one is to create an account
                </p>
              ) : cardType === "Investor" ? (
                <>
                  {location.state.addressSignupData.address && (
                    <>
                      {location.state.addressSignupData.searchTab ===
                      "property" ? (
                        <p>
                          To see about investing in{" "}
                          <span className="font-weight-bold">
                            {location.state.addressSignupData.address &&
                              location.state.addressSignupData.address.substr(
                                0,
                                location.state.addressSignupData.address.indexOf(
                                  ","
                                )
                              )}
                          </span>{" "}
                          and similar properties, step one is to create an
                          account
                        </p>
                      ) : (
                        <p>
                          To stay updated on deals available in{" "}
                          <span className="font-weight-bold">
                            {location.state.addressSignupData.address &&
                              location.state.addressSignupData.address.substr(
                                0,
                                location.state.addressSignupData.address.indexOf(
                                  ","
                                )
                              )}
                            ,{" "}
                          </span>{" "}
                          step one is to create an account.{" "}
                        </p>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )} */}


      <div className="container">
          <div className="align-items-center property-card-signup-wrapper">
            <div className="col-sm-5">
              {props.searchTab === "property" ? (
                <PropertyRegionStreetView
                  lat={parseFloat(props && props.lat)}
                  lng={parseFloat(props && props.lng)}
                  height={"85px"}
                />
              ) : (
                <PropertyRegionMap
                  lat={parseFloat(props && props.lat)}
                  lng={parseFloat(props && props.lng)}
                  height={"85px"}
                />
              )}
            </div>
            <div className="col-sm-7">
              {props.cardType === "Owner" ? (
                <p>
                  To claim{" "}
                  <span className="font-weight-bold">
                    {props && props.address &&
                      props && props.address.substr(
                        0,
                        props && props.address.indexOf(",")
                      )}
                  </span>
                  . as yours, step one is to create an account
                </p>
              ) : props.cardType === "Investor" ? (
                <>
                  {props.address && (
                    <>
                      {props.searchTab === "property" ? (
                        <p>
                          To see about investing in{" "}
                          <span className="font-weight-bold">
                            {props && props.address &&
                              props && props.address.substr(
                                0,
                                props && props.address.indexOf(
                                  ","
                                )
                              )}
                          </span>{" "}
                          and similar properties, step one is to create an
                          account
                        </p>
                      ) : (
                        <p>
                          To stay updated on deals available in{" "}
                          <span className="font-weight-bold">
                            {props &&props.address &&
                              props &&props.address.substr(
                                0,
                                props &&props.address.indexOf(
                                  ","
                                )
                              )}
                            ,{" "}
                          </span>{" "}
                          step one is to create an account.{" "}
                        </p>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>


    </>
  );
}
