import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_createTaxInfo=(data, callback)=>{
    axios
    .post(`/tax-info/createTaxInfo`,{ data }, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getTaxInfo=(callback)=>{
    axios
    .get(`/tax-info/getTaxInfo`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}
