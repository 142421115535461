import React, { Component } from "react";
import "../PropertyPage.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { get_image_full_url } from "../../utills/helper";


class HeroGallery extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			photoIndex: 0,
			isOpen: false,
		};
	}
	
	render() {
		// const images = [
		// 	{"image":"https://images.pexels.com/photos/3861943/pexels-photo-3861943.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"},
		// 	{"image":"https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
		// 	{"image": "https://images.pexels.com/photos/270348/pexels-photo-270348.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
		// ];
		const images = this.props.imagesList
		const { photoIndex, isOpen } = this.state;

		return (
			<div>
				<button
					className="showmore-btn"
					type="button"
					onClick={() => this.setState({ isOpen: true })}
				>
					Show More
				</button>

				{isOpen && (
					<Lightbox
						mainSrc={`${get_image_full_url(images[photoIndex]['image'])}`}
						nextSrc={images[(photoIndex + 1) % images.length]}
						prevSrc={images[(photoIndex + images.length - 1) % images.length]}
						onCloseRequest={() => this.setState({ isOpen: false })}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (photoIndex + 1) % images.length,
							})
						}
					/>
				)}
			</div>
		);
	}
}

export default HeroGallery;
