import React from "react";
import { useDropzone } from "react-dropzone";
import FontAwesomeIcon from "react-fontawesome";
import { onClose } from "../../utills/remove-docs";
import "../../Page/Accreditation/accreditation.css";
import { get_image_full_url } from "../../utills/helper";
import { axios_deletePropertyImageById } from "../../utills/property-routes";
import { toast } from 'react-toastify';

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
  width: 200,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const xC = {
  position: "absolute",
  top: 0,
  right: 0,
  color: "white",
  fontWeight: "bold",
  fontSize: 20,
  border: "1px solid grey",
  cursor: "pointer",
  padding: 5,
  margin: 5,
  background: "grey",
};

const UploadImages = ({
  files,
  setFiles,
  setNewFiles,
  newFiles,
  oldFiles,
  setOldFiles,
}) => {


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) =>
        [...prevFiles].concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      );
      setNewFiles((prevFiles) =>
        [...prevFiles].concat(
          acceptedFiles.map((e) => e.name.replace(/\s/g, ""))
        )
      );
    },
  });

  //delete property image api
  

  //handle image Delete
  const handleDeleteImage=(id, oldFiles, file, setOldFiles,image)=>{
    const imageConfirmBox = window.confirm(
      "Do you really want to permanently delete this Property Image? \n Be careful, This process cannot be undone."
    )
    if (imageConfirmBox === true) {
      axios_deletePropertyImageById(id,file.image).then((response)=>{
        if(response.data.status === true){
          onClose(oldFiles, file, setOldFiles);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }else{
          toast.error(response.data.message,{
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch((err)=>{
          console.log("Oops! Something went wrong.", err);
          toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      })
    }
  }


  //current image uploaded
  const thumbs =
    files &&
    files.map((file) => (
      <div style={thumb} key={file && file.name}>
        <div style={thumbInner}>
          <img src={file && file.preview} style={img} alt="thumbnail Inner" />
        </div>
        <div onClick={() => onClose(files, file, setFiles)} style={xC}>
          x
        </div>
      </div>
    ));

  //database images
  const thumbs2 =
    oldFiles &&
    oldFiles.map((file, index) => {

      return (
        <div style={thumb} key={'img-' + index}>
          <div style={thumbInner}>
            <img
              src={`${get_image_full_url(file['image'])}`}
              style={img}
              alt="Property Img"
            />
          </div>
          <div onClick={() => handleDeleteImage(file['id'], oldFiles, file, setOldFiles)} style={xC}>
            x
          </div>
        </div>
      );
    });

  return (
    <section className="container text-center">
      <div className="row justify-content-center text-center align-items-center">
        <div className="col-lg-12">
          <div
            style={{ width: "100%" }}
            className="main-upload mt-3 text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="uploading mt-5 text-center">
              <FontAwesomeIcon size={"3x"} name="fa-solid fa-upload" />
              <h6 className="mt-3 font-weight-bold">
                Upload Property Images or drag Images here
              </h6>
            </div>
          </div>
        </div>
      </div>

      <aside style={thumbsContainer}>{thumbs}</aside>
      {oldFiles && oldFiles.length > 0 && (
        <aside style={thumbsContainer}>{thumbs2}</aside>
      )}
    </section>
  );
};

export default UploadImages;
