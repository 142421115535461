import axios from "./axios-server";

import { toast } from 'react-toastify';
toast.configure()  // toast-configuration method,




export const axios_addProperty = (
  property_type,
  mortage_balance,
  desired_cash,
  estimated_property_value,
  property_address,
  builtup_area,
  bathrooms,
  bedrooms,
  lat,
  lng,
  uploaded_photos
) => {
  return axios.post(
    "/property/add",
    {
      property_type,
      mortage_balance,
      desired_cash,
      estimated_property_value,
      property_address,
      builtup_area,
      bathrooms,
      bedrooms,
      lat,
      lng,
      uploaded_photos,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const axios_createProperty = (property_address) => {
  return axios.post(
    "/property/add",
    { property_address },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const axios_updateProperty = (email, password) => {
  return axios.post(
    "/property/update",
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const axios_deleteProperty = (email, password) => {
  return axios.post(
    "/property/delete",
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const axios_loadProperty = (id) => {
  return axios.get("/property/view/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_loadProperties = (page, limit) => {
  return axios.get("/property/list/" + page + "/" + limit, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("userToken"),
    },
  });
};
export const axios_searchProperties = (page, limit) => {
  return axios.get("/property/search/?page=" + page + "&size=" + limit, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_searchPropertyByAddressZipcode = (address, zipcode) => {
  return axios.get(
    "/search/property/?streetAddress=" + address + "&zipCode=" + zipcode,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const axios_createOwnerProperty = (data, callback) => {
  axios
    .post(
      `/owners-properties/createOwnersProperties`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": data.token,
        },
      }
    )
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong.", error);
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
    });
};
export const axios_getOwnerProperty = (page, callback) => {
  axios
    .get(`/owners-properties/getOwnersProperties/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("userToken"),
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong.", error.response);
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
    });
};
export const axios_createInvestorProperty = (data, callback) => {
  axios
    .post(
      `/investors-properties/createInvestorsProperties`,
      {
        data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": data.token,
        },
      }
    )
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong.", error.response);
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
    });
};
export const axios_getInvestorProperty = (page, callback) => {
  axios
    .get(`/investors-properties/getInvestorProperties/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong.", error);
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
    });
};

export const axios_getSingleProperty = (id) => {
  return axios.get(`/property/single-property-data/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_getSinglePropertyByCode = (code) => {
  return axios.get(`/property/single-property-data-code/${code}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const editProperties = (setloading, setOpen, data) => {
  return axios
    .patch(`/property/edit-property`, data)
    .then((res) => {
      if (res) {
        setloading(false);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
      toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT })
    })
    .catch((err) => {
      // console.log(err);
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT })
      setloading(false);
      setOpen(false);
    });
};


export const axios_deletePropertyImageById = (id, image) => {
  return axios
    .delete(
      `/property/delete-property-image-by-id/${id}/${image}`, {
      headers: {
        "Content-Type": "application/json"
      }
    }
    )
};


export const axios_getInvestorPropertiesByCode = (code, callback) => {
  axios
    .get(`/investors-properties/getInvestorPropertiesByCode/${code}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong.", error);
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
    });
};
