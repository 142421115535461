import { toast } from 'react-toastify';
import axios from './axios-server';


export const axios_createVerificationSection = (callback) => {
    return axios.post('stripe/create-verification-session', 
    { data: "" }, 
    {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error("Oops! Something went wrong.",{position:toast.POSITION.TOP_RIGHT})
    });
}