import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_getPaymentsInvestor=(page, limit, callback)=>{
    axios
    .get(`payment-distribution/getPaymentsYouReceived/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})    });  
}

export const axios_getPaymentsYouReceivedByCode=(code, callback)=>{
    axios
    .get(`payment-distribution/getPaymentsYouReceivedByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})    });  
}