import React, { useEffect, useState } from "react";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import { useParams } from "react-router-dom";
import { axios_getIssuanceByCode } from "../../utills/issuance-routes";
import { str_into_float_num, str_into_num } from "../../utills/string-into-number";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function IssuancesOwnerDetails() {
  const history = useHistory();

  const { code } = useParams();
  const [IssuancesOwnerData, setIssuancesOwnerData] = useState();

  useEffect(() => {
    const getIssuanceCallback = (response) => {
      if (response.status === 200) {
        if (response.data.status === true) {
          setIssuancesOwnerData(response.data.data);
        }
      }
      if (response.data.data === null) {
        toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})

        history.push("/issuancesowner");
      }
    };
    axios_getIssuanceByCode(code, getIssuanceCallback);
  }, [code, history]);

  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Home Equity Details You Issued</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {IssuancesOwnerData != null ? (
                  <div>
                    <ul>
                      <li>
                        <b>Issuances Amount: </b> ${str_into_num(IssuancesOwnerData.amount)}
                      </li>
                      <li>
                        <b>yield: </b> {str_into_float_num(IssuancesOwnerData.yield * 100)}%
                      </li>
                      <li>
                        <b>Appraised Value:</b> ${str_into_num(IssuancesOwnerData.appraised_value)}
                      </li>
                      <li>
                        <b>Status:</b> {IssuancesOwnerData.status}
                      </li>
                      <li>
                        <b>Contract Length:</b> {IssuancesOwnerData.contract_length}{" "}
                        {IssuancesOwnerData.contract_length === 1 ? " year" : " years"}
                      </li>
                      <li>
                        <b>Date:</b>{" "}
                        {moment(IssuancesOwnerData.created_at).isValid()
                          ? moment(IssuancesOwnerData.created_at).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Property Address:</b>{" "}
                        {IssuancesOwnerData.auction.property.property_address}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
