import "./editProperty.css";
import React, { useState, useEffect } from "react";
import Sidenav from "../Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { useHistory, useParams } from "react-router-dom";
import { axios_getSingleProperty, editProperties } from "../../utills/property-routes";
import UploadImages from "./UploadImages";
import Head from "../../Component/Head";
import { toast } from "react-toastify";

const objectToFormData = (data, formData, namespace) => {
  let fd = formData || new FormData();

  if (Array.isArray(data)) {
    data.forEach((item) => {
      fd.append(namespace, item);
    });
  }

  return fd;
};

const EditProperty = () => {
  const { id } = useParams();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [bedrooms, setBedrooms] = useState();
  const [bathrooms, setBathrooms] = useState();
  const [builtupArea, setBuiltupArea] = useState();
  const [address, setaddress] = useState();
  const [estimatedPropertyValue, setEstimatedPropertyvalue] = useState();
  const [desiredCash, setDesiredCash] = useState();
  const [mortageBalance, setMortageBalance] = useState();
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  // const [filesObj, setFilesObj] = useState([]);
  // const [status, setstatus] = useState();
  const [propId, setpropId] = useState();
  const [loading, setLoading] = useState(true);
  // const allfILES = oldFiles && oldFiles.concat(newFiles);

  useEffect(() => {
    const getPropertyData = async () => {
      const response = await axios_getSingleProperty(id);
      if (response) {
        const property = response.data.data;
        setPropertyType(property && property.property_type);
        setBedrooms(property && property.bedrooms);
        setBathrooms(property && property.bathrooms);
        setBuiltupArea(property && property.builtup_area);
        setaddress(property && property.property_address);
        setEstimatedPropertyvalue(property && property.estimated_property_value);
        setDesiredCash(property && property.desired_cash);
        setMortageBalance(property && property.mortage_balance);
        // setstatus(property && property.status);
        setpropId(property && property.id);
        setLoading(false);

        // var images = getImagesArray(property.images2);

        setOldFiles(property && property.properties_images);
        // if (Image_URL === "http://localhost:4000") {
        //   setOldFiles(images);
        // } else {
        //   setOldFiles(images);
        // }
      }
      if (response.data.status === false) {
        toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
        history.push("/")
      }
    };
    getPropertyData();
  }, [id]);

  const inputHandler = (e, setState) => {
    setState(e.target.value);
  };

  // const handleChange = (event) => {
  //   setstatus(event.target.value);
  // };

  const handlerSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bedrooms", parseInt(bedrooms));
    formData.append("bathrooms", parseInt(bathrooms));
    formData.append("property_type", propertyType);
    formData.append("property_address", address);
    formData.append("builtup_area", parseInt(builtupArea));
    formData.append("estimated_property_value", parseInt(estimatedPropertyValue));
    formData.append("id", propId);
    formData.append("code", id)
    formData.append("desired_cash", parseInt(desiredCash));
    formData.append("mortage_balance", parseInt(mortageBalance));
    formData.append("property_images", newFiles);
    // formData.append("status", status);
    objectToFormData(files, formData, "propertyImages");

    editProperties(setLoading, setOpen, formData);
    setTimeout(() => {
      history.push(`/property-details/${id}`)
    }, 3000);
  };

  return (
    <div className="Dashboard">
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <Head title="Edit Property" />
          <div className="dashTitle">
            <h4>Edit property</h4>
          </div>
          <Sidenav />
        </div>
        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>
          <div className="DashContent">
            <div className="main_box d-flex justify-content-center align-items-center">
              {!loading && (
                <div className="box p-4">
                  <form onSubmit={(e) => handlerSubmit(e)}>
                    <div className="row ">
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="propertyType" className="">
                            Property Type
                          </label>
                          <input
                            // required
                            type="text"
                            className=""
                            id="propertyType"
                            placeholder="e.g Mobile Home 1"
                            value={propertyType}
                            onChange={(e) => inputHandler(e, setPropertyType)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="bedrooms" className="">
                            Bedrooms
                          </label>
                          <input
                            // required
                            type="number"
                            min="0"
                            className=""
                            id="bedrooms"
                            placeholder="e.g 4"
                            value={bedrooms}
                            onChange={(e) => inputHandler(e, setBedrooms)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="bathrooms" className="">
                            Bathrooms
                          </label>
                          <input
                            // required
                            min="0"
                            type="number"
                            className=""
                            id="bathrooms"
                            placeholder="e.g 2"
                            value={bathrooms}
                            onChange={(e) => inputHandler(e, setBathrooms)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="builtupArea" className="">
                            Builtup Area
                          </label>
                          <input
                            // required
                            type="number"
                            min="0"
                            className=""
                            id="buildtuparea"
                            placeholder="e.g 2002"
                            value={builtupArea}
                            onChange={(e) => inputHandler(e, setBuiltupArea)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="estimatedPropertyValue" className="">
                            Est. Home Value
                          </label>
                          <input
                            // required
                            min="0"
                            type="number"
                            className=""
                            id="estimatedPropertyValue"
                            placeholder="e.g 23000"
                            value={estimatedPropertyValue}
                            onChange={(e) => inputHandler(e, setEstimatedPropertyvalue)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="desiredCash" className="">
                            Desired Cash
                          </label>
                          <input
                            // required
                            min="0"
                            type="number"
                            className=""
                            id="desiredCash"
                            placeholder="e.g 20000"
                            value={desiredCash}
                            onChange={(e) => inputHandler(e, setDesiredCash)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="mortageBalance" className="">
                            Mortage Balance
                          </label>
                          <input
                            // required
                            min="0"
                            type="number"
                            className=""
                            id="mortageBalance"
                            placeholder="e.g 22000"
                            value={mortageBalance}
                            onChange={(e) => inputHandler(e, setMortageBalance)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <select
                            value={status}
                            className="form-select"
                            onChange={handleChange}
                            aria-label="Default select example">
                            <option value="Active">Active</option>
                            <option value="Inactive">In Active</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <label htmlFor="status">Property Address</label>
                        <div className="mb-3">
                          <textarea
                            // required
                            type="text"
                            className=""
                            id="address"
                            placeholder="e.g 22000"
                            value={address}
                            onChange={(e) => inputHandler(e, setaddress)}
                            rows={6}
                            style={{ width: "100%", borderRadius: 5 }}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <UploadImages
                          files={files}
                          setFiles={setFiles}
                          setNewFiles={setNewFiles}
                          newFiles={newFiles}
                          oldFiles={oldFiles}
                          setOldFiles={setOldFiles}
                        />
                      </div>
                      <div className="col-12">
                        <div className="mb-3 d-flex justify-content-center align-items-center">
                          <button
                            style={{ backgroundColor: "#000000" }}
                            type="submit"
                            className="btn btn-secondary mt-3">
                            Update Property
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProperty;
