import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";

// import { connect } from "react-redux";
// import store from "../../store/store";
// import { loadUser, startLoading, logout } from "../../store/actions/auth";

import Sidebar from "../../Component/Sidebar/Sidebar";
import AuctionListTable from "../../Component/auction-list-table/AuctionListTable";

import "../../components/Dashboard.css";
import { axios_loadAuctions } from '../../utills/auction-routes';

export default function OwnerAuctions() {

  const [isLoading, setIsLoading] = useState(true);
  const [auctionList, setAuctionList] = useState([])
  const [totalPageCount, setTotalPageCount] = useState()
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    document.title = "Exuity | Launch a home equity auction";
  }, [])


  useEffect(() => {
    getAuctionListData(currentPage)
  }, [currentPage])

  async function getAuctionListData(currentPage) {
    setIsLoading(true)
    axios_loadAuctions(currentPage).then((response) => {
      if (response.data.status && response.data.data.count) {
        setTotalPageCount(response.data.data.count);
        setAuctionList(response.data.data.rows);
      }
    }).catch((error) => {
      console.log("Oops Something went wrong", error);
    }).finally(() =>
      setIsLoading(false)
    )
  }




  return (
    <div className="Dashboard" id="Dashboard">
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <div className="dashTitle">
            <h4>Auctions You Launched</h4>
            {/* {this.state.found === 2 && (
                <div className="createauction-btn">
                  <NavLink to="/auctions/create">
                    <button type="button">Create Auction</button>
                  </NavLink>
                </div>
              )} */}
          </div>
        </div>

        <div className="row">
          <Sidebar />
          <div className="DashContent">

            <AuctionListTable
              isLoading={isLoading}
              tableData={auctionList}
              totalPageCount={totalPageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />

            <div className="launched-auc-sec"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
