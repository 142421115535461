import React, { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";

import "../../components/equitygraph.css";
import "../../App.css";
import "intro.js/introjs.css";
import "././tutorialcomponent.css";


const TrutorialComponent = (props) => {
  const [enabledInvesterSteps, setInvesterSteps] = useState(false);
  const [enabledOwnerrSteps, setOwnerSteps] = useState(false);
  const [tutorialInitialStep, setTutorialInitialStep] = useState(0);
  const [defaultActiveTab, setDefaultActiveTab] = useState();
  const heroData = useSelector((state) => state);
  const { investerSteps, ownerStep, tab, setTabs, current } = props;

  const exit = () => {
    setOwnerSteps(false);
    setInvesterSteps(false);
    props.setIsTutorialModalOpen(false);
  };

  const currentClassesHandler = (current, classname) => {
    return current !== "homepage" ? classname : "";
  };

  useEffect(() => {
    if (heroData.heroData.valueGroups.sellBuy !== undefined) {
      setTabs(heroData.heroData.valueGroups.sellBuy === "buy" ? 0 : 1);
    }
  }, [heroData.heroData.valueGroups.sellBuy, setTabs]);

  useEffect(() => {
    if (heroData.heroData.valueGroups.sellBuy !== undefined) {
      setDefaultActiveTab(heroData.heroData.valueGroups.sellBuy === "buy" ? "Investor" : "Owner");
    }
  }, [heroData.heroData.valueGroups.sellBuy]);

  useEffect(() => {
    if (props.isTutorialModalOpen === true) {
      tab === 0 ? setOwnerSteps(true) : setInvesterSteps(true);
    }
  }, [props.isTutorialModalOpen, tab]);

  useEffect(() => {
    if (props.tutorialModalInitialStep !== undefined) {
      setTutorialInitialStep(props.tutorialModalInitialStep);
    }
  }, [props.tutorialModalInitialStep]);

  useEffect(() => {
    if (enabledOwnerrSteps || enabledInvesterSteps) {
      props.setIsTutorialModalOpen(true);
    }
  }, [enabledOwnerrSteps, enabledInvesterSteps, props]);
  

  return (
    <div className={currentClassesHandler(current, `equity-graph`)}>
      <div className={currentClassesHandler(current, `equity-graph-wrapper`)}>
        <div className={currentClassesHandler(current, `main_equity text-centerr`)}>
          <h3 className="quity-title">Numerical Example</h3>
          <p className="exuity-para">
            The numerical example below simulates every possible scenario. Choose the perspective of
            owner or investor, and start the tutorial or click the output numbers to learn the math
            and how it works.
          </p>

          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <span
                className={defaultActiveTab === "Owner" ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                role="tab"
                aria-controls="Owner"
                aria-selected="false"
                onClick={() => {
                  setDefaultActiveTab("Owner");
                  setTabs(0);
                }}>
                Owner
              </span>
            </li>
            <li className="nav-item">
              <span
                className={defaultActiveTab === "Investor" ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                role="tab"
                aria-controls="Investor"
                aria-selected="false"
                onClick={() => {
                  setDefaultActiveTab("Investor");
                  setTabs(1);
                }}>
                Investor
              </span>
            </li>
          </ul>

          <Steps
            enabled={tab === 0 ? enabledOwnerrSteps : enabledInvesterSteps}
            steps={tab === 0 ? ownerStep : investerSteps}
            initialStep={tutorialInitialStep}
            onExit={() => exit()}
            doneLabel="Done"
            onChange={(e)=>{props.setTutorialModalInitialStep(e)}}
          />
          {tab === 0 ? (
            <button
              style={{
                height: 50
              }}
              type="button"
              className="mt-3 glowing-btn"
              onClick={() => setOwnerSteps(true)}>
              Start Tutorial
            </button>
          ) : (
            <button
              style={{
                height: 50
              }}
              type="button"
              className="mt-3 glowing-btn"
              onClick={() => setInvesterSteps(true)}>
              Start Tutorial
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrutorialComponent;
