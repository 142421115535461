import React from "react";

import "../Dashboard.css";
import Sidenav from "../Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { saveAuction } from "../../store/actions/auction";
import { axios_loadProperties } from "../../utills/property-routes";
import store from "../../store/store";

import { setToast } from "../../store/actions/toast";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import subDays from "date-fns/subDays";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuctionCreateForm from "./AuctionCreateForm";
import Head from "../../Component/Head";
import { toast } from "react-toastify";

class AuctionCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isSubmitted: false,
      submitting: false,
      propertyType: "",
      mortageBalance: "",
      desiredCash: "",
      estimatedPropertyValue: "",
      propertyAddress: "",
      propertyAddressParts: "",
      builtupArea: "",
      Bathrooms: "",
      Bedrooms: "",
      address: "",
      title: "",
      property_id: "",
      properties: [],
      amount_raising: "",
      max_yield: "",
      contract_length: "",
      start_date: ""
    };
  }
  loadProperties() {
    axios_loadProperties(1, 1000)
      .then((value) => {
        this.setProperties(value.data.data);
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.", err);
        toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      });
  }
  setProperties(properties) {
    this.setState({ properties: properties });
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    this.loadProperties();
  }
  openProperty() {
    this.setState({
      isVisible: true,
      isSubmitted: true
    });
  }
  setAddress(val) {
    if (typeof val != "undefined") {
      this.setState({ propertyAddress: val.label });
    }
  }
  basicInfo() {
    if (this.state.property_id !== "") {
      this.setState({ isVisible: !this.state.isVisible });
    } else {
      if (this.state.property_id !== "") {
        toast.error("Please select a Property", { position: toast.POSITION.TOP_RIGHT });
      }
    }
  }
  propertyCost() {
    if (
      this.state.amount_raising !== "" &&
      this.state.max_yield !== "" &&
      this.state.max_yield <= 15 &&
      this.state.max_yield > 0 &&
      this.state.contract_length !== "" &&
      this.state.contract_length >= 1 &&
      this.state.contract_length <= 15 &&
      this.state.start_date !== ""
    ) {
      store
        .dispatch(
          saveAuction(
            this.state.property_id,
            this.state.amount_raising,
            this.state.max_yield,
            this.state.contract_length,
            this.state.start_date
          )
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("Oops! Something went wrong.", err);
          toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
        });
    } else {
      if (this.state.amount_raising === "") {
        toast.error("Please provide Amount Desired", { position: toast.POSITION.TOP_RIGHT });
      } else if (this.state.max_yield === "") {
        toast.error("Please provide max yield", { position: toast.POSITION.TOP_RIGHT });
      } else if (this.state.max_yield >= 15 || this.state.max_yield <= 0) {
        toast.error("Yield % must be between 0% and 15%", { position: toast.POSITION.TOP_RIGHT });
      } else if (this.state.contract_length === "") {
        toast.error("Please provide contract length", { position: toast.POSITION.TOP_RIGHT });
      } else if (this.state.contract_length < 1 || this.state.contract_length >= 15) {
        toast.error("Contract length must be between 1 and 15 years", {
          position: toast.POSITION.TOP_RIGHT
        });
      } else if (this.state.start_date === "") {
        toast.error("Please provide Auction Launch Date", { position: toast.POSITION.TOP_RIGHT });
      }
    }
  }

  setPropertyId(id) {
    console.log(id);
    this.setState({ property_id: id });
  }

  render() {
    const address = this.state.address;
    // const setAddress = this.setAddress();
    const propt = this;
    const { property_id } = this.state;
    return (
      <div className="Dashboard" id="Dashboard">
        <Head title={`Exuity | Launch a home equity auction`} />
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Apply to Launch an Auction</h4>
            </div>
          </div>
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>
            <div className="DashContent">
              {this.props.user ? (
                <div className="AddProperty">
                  {/* <ToastProvider>
                    <Toast />
                  </ToastProvider> */}
                  {this.state.isVisible ? (
                    this.state.isSubmitted ? (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              Hi, {this.props.user.first_name}! Let’s get started listing your
                              space.
                            </h5>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <div className="AddProperty-form">
                              <ul className="AddProperty-inputs">
                                <li>
                                  <label className="input-label">Property Address</label>

                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyAAkHqRzQUrkvifRENNrC3kKSRwdhX6_Qc&libraries"
                                    selectProps={{
                                      address,
                                      onChange: (o) => {
                                        this.setAddress(o);
                                      }
                                    }}
                                  />
                                </li>
                                <li>
                                  <label className="input-label">Built Up Area</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    name="Area"
                                    placeholder="sq.ft."
                                    value={this.state.builtupArea}
                                    onChange={(e) =>
                                      this.setState({
                                        builtupArea: e.target.value
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">Bedrooms</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    name="Bedrooms"
                                    placeholder="1"
                                    value={this.state.Bedrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        Bedrooms: e.target.value
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">Bathrooms</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    name="Bathrooms"
                                    placeholder="1"
                                    value={this.state.Bathrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        Bathrooms: e.target.value
                                      })
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="Ap-submitted-btn"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              {this.props.user.first_name} submit some basic info about the money
                              you'd like to get out of your property. Don't worry, it's not binding;
                              we'll contact you to discuss all the details before launching it.
                            </h5>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <AuctionCreateForm property_id={this.state.property_id} />
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="AddPropertyGroup">
                      <div className="AddProperty-ls">
                        <div className="AddProperty-t">
                          <h5>
                            {this.props.user.first_name} submit some basic info about the money
                            you'd like to get out of your property. Don't worry, it's not binding;
                            we'll contact you to discuss all the details before launching it.
                          </h5>
                        </div>
                        <div className="AddProperty-illustration">
                          <img
                            src={require("../../Images/AddProperty-illustration.png")}
                            alt="Add Property Home Illustration"
                          />
                        </div>
                      </div>
                      <div className="AddProperty-rs">
                        <div className="AddProperty-rs-Group">
                          <div className="property-type-tags">
                            <h6>Choose the property you'd like to launch an auction for:</h6>
                            <div className="property-tags">
                              {this.state.properties.map(function (c, i) {
                                return (
                                  <button
                                    className={property_id === c.id && "active"}
                                    key={c.id}
                                    onClick={() => propt.setPropertyId(c.id)}>
                                    {c.property_address}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                          <div className="Ap-submitted-btn">
                            <button onClick={() => this.basicInfo()}>Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user
});
export default connect(mapStatetoProps, {
  loadUser,
  startLoading,
  logout,
  setToast,
  saveAuction
})(AuctionCreate);
