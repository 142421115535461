import React, { useEffect, useState } from "react";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import { useParams } from "react-router-dom";
import { axios_getPaymentMadeDetailsByCode } from "../../utills/paymentMade-routes";
import { str_into_num } from "../../utills/string-into-number";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function PaymentMadeDetails() {
  const history = useHistory();
  const { code } = useParams();
  const [paymentMadeData, setPaymentMadeData] = useState();

  useEffect(()=>{
    axios_getPaymentMadeDetailsByCode(code)
    .then((response)=>{
      if(response.status===200){
        if(response.data.status===true){
          if(response.data.data.payments_owed.issuance==null){
            toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
            history.push("/paymentmade")
          }
          setPaymentMadeData(response.data.data)
        }
      }
    }).catch(error=>{
    console.log("Oops! Something went wrong",error.response.data)
    toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    history.push("/paymentmade")
  })
  },[code,history])
 
  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Payment Made Detail</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {paymentMadeData != null ? (
                  <div>
                    <ul>
                      <li>
                        <b>Payment Amount: </b>${paymentMadeData.amount===null?"-":str_into_num(paymentMadeData.amount)}
                      </li>
                      <li>
                        <b>Payment Method:</b> {paymentMadeData.payment_method===null?"-":paymentMadeData.payment_method}
                      </li>
                      <li>
                        <b>Payment Owed Home Value:</b> $
                        {str_into_num(paymentMadeData.payments_owed.home_value)}
                      </li>
                      <li>
                        <b>Payment Date:</b>{" "}
                        {moment(paymentMadeData.payment_date).isValid()
                          ? moment(paymentMadeData.payment_date).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Property Address:</b>{" "}
                        {paymentMadeData.payments_owed.issuance.auction.property.property_address}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
