import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import InputRange from "react-input-range";
import ReactTooltip from "react-tooltip";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import CalculationModal from "../../Page/model-page/CalculationModal";
import TutorialComponent from "../../Component/tutorial-component/TutorialComponent";
import useModal from "../../Page/model-page/useModal";

import { InvesterSteps, Owner_steps } from "../../utills/StepsOwner";
import { str_into_float_num, str_into_num } from "../../utills/string-into-number";

import "intro.js/introjs.css";
import "../../components/equitygraph.css";
import "../../Page/model-page/calculationmodal.css";

const NewEquity = (props) => {
  const [dragging, setdragging] = useState();
  const tableDataArray = [];
  const [tableDataState, setTableDataState] = useState([]);
  const heroData = useSelector((state) => state.heroData.valueGroups);

  //outputs states
  const [propertySellsFor, setPropertySellsFor] = useState();
  const [investorEntitledTo, setInvestorEntitledTo] = useState();
  const [APR, setAPR] = useState();
  const [totalAnnualPaymentsMade, setTotalAnnualPaymentsMade] = useState();
  const [payoutFromSale, setPayoutFromSale] = useState();
  const [totalPaybackToInvestors, setTotalPaybackToInvestors] = useState();
  const { isShowing, toggle } = useModal();

  const modalRef = useRef();

  useEffect(() => {
    const options = {
      reserveScrollBarGap: true
    };
    if (isShowing) {
      disableBodyScroll(modalRef, options);
    } else {
      enableBodyScroll(modalRef);
    }
  }, [isShowing, modalRef]);

  const addDragStart = () => {
    setdragging("dstart");
  };
  const removeDragStart = () => {
    setdragging("");
  };

  const showHeadings = (active, H1, H2) => {
    if (active === 0) {
      return H1;
    } else if (active === 1) {
      return H2;
    } else {
      return H1;
    }
  };

  const TutorialClass = (tab, c1, c2) => {
    if (tab === 0) {
      return c1;
    } else if (tab === 1) {
      return c2;
    } else {
      return c1;
    }
  };

  const [appraisesVal, setappraisesVal] = useState(1000000); // homevalue
  const [investment, setinvestment] = useState(100000);
  const [percentOfValueSold, setPercentOfValueSold] = useState(10);
  const [yeild, setyield] = useState(5);
  const [paymentM, setPaymentM] = useState(60);
  const [year, setYear] = useState(5);
  const [annualApperciation, setannualApperciation] = useState(6); // appreciation
  const [tab, setTab] = useState(0);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const [tutorialModalInitialStep, setTutorialModalInitialStep] = useState(0);

  //slider intro states
  const [isSliderAnimate, setIsSliderAnimate] = useState(false);
  const [sliderAppraisesVal, setSliderAppraisesVal] = useState(1000000); // homevalue
  const [sliderPercentOfValueSold, setSliderPercentOfValueSold] = useState(10);
  const [sliderPaymentM, setSliderPaymentM] = useState(60);
  const [sliderAnnualApperciation, setSliderAnnualApperciation] = useState(6); // appreciation
  const [sliderYear, setSliderYear] = useState(5);
  const [sliderYeild, setSliderYield] = useState(5);

  useEffect(() => {
    if (props.est_value !== undefined) {
      setappraisesVal(props.est_value);
    }
    if (props.homevalue !== undefined) {
      setappraisesVal(props.homevalue);
    }
    if (props.yieldValue !== undefined) {
      setyield(props.yieldValue);
    }
    if (props.appreciation !== undefined) {
      setannualApperciation(props.appreciation);
    }
  }, [props]);

  useEffect(() => {
    if (heroData.homeValue !== undefined) {
      setappraisesVal(heroData.homeValue);
    }
    if (heroData.sellBuy !== undefined) {
      setTab(heroData.sellBuy === "buy" ? 1 : 0);
    }
    if (heroData.percentage !== undefined) {
      setPercentOfValueSold(heroData.percentage);
    }
  }, [heroData]);

  const [data, setDataTable] = useState([
    {
      year: 0
    },
    {
      year: 1
    },
    {
      year: 2
    },
    {
      year: 3
    },
    {
      year: 4
    },
    {
      year: 5
    }
  ]);

  const yieldHanlder = (value) => {
    if (value === 0) {
      setYear(value);
      const delData = data.splice(1);
      setDataTable(delData);
    } else {
      var arr = Array.apply(null, { length: value + 1 });
      if (value) {
        setYear(value);
        setDataTable(arr);
      }
    }
  };

  const annualApperciationHandler = (num) => {
    return (num * annualApperciation) / 100;
  };

  const newArr = (appraisesVal, index) => {
    if (index === 0) {
      return localStorage.setItem("0", appraisesVal);
    } else if (index === 1) {
      const first = JSON.parse(localStorage.getItem("0"));
      return localStorage.setItem("1", first + annualApperciationHandler(first));
    } else if (index === 2) {
      const second = JSON.parse(localStorage.getItem("1"));
      return localStorage.setItem("2", second + annualApperciationHandler(second));
    } else if (index === 3) {
      const third = JSON.parse(localStorage.getItem("2"));
      return localStorage.setItem("3", third + annualApperciationHandler(third));
    } else if (index === 4) {
      const fourth = JSON.parse(localStorage.getItem("3"));
      return localStorage.setItem("4", fourth + annualApperciationHandler(fourth));
    } else if (index === 5) {
      const fourth = JSON.parse(localStorage.getItem("4"));
      return localStorage.setItem("5", fourth + annualApperciationHandler(fourth));
    } else if (index === 6) {
      const fourth = JSON.parse(localStorage.getItem("5"));
      return localStorage.setItem("6", fourth + annualApperciationHandler(fourth));
    } else if (index === 7) {
      const fourth = JSON.parse(localStorage.getItem("6"));
      return localStorage.setItem("7", fourth + annualApperciationHandler(fourth));
    } else if (index === 8) {
      const fourth = JSON.parse(localStorage.getItem("7"));
      return localStorage.setItem("8", fourth + annualApperciationHandler(fourth));
    } else if (index === 9) {
      const fourth = JSON.parse(localStorage.getItem("8"));
      return localStorage.setItem("9", fourth + annualApperciationHandler(fourth));
    } else if (index === 10) {
      const fourth = JSON.parse(localStorage.getItem("9"));
      return localStorage.setItem("10", fourth + annualApperciationHandler(fourth));
    }

    return appraisesVal;
  };

  useEffect(() => {
    setinvestment((percentOfValueSold / 100) * appraisesVal);
  }, [appraisesVal, percentOfValueSold]);

  useEffect(() => {
    let sumOfPaymentMade = 0;
    for (let index = 0; index < data.length; index++) {
      newArr(appraisesVal, index);
      const val = JSON.parse(localStorage.getItem([index]));

      let investorEquityStake = (investment / appraisesVal) * 100;

      if (index === 1) {
        investorEquityStake =
          (investment / appraisesVal) * 100 + tableDataArray[index - 1].EquityIncrease;
      } else if (index >= 2) {
        investorEquityStake =
          tableDataArray[index - 1].InvestorEquityStake + tableDataArray[index - 1].EquityIncrease;
      }

      let anualPayment = val * (investorEquityStake / 100) * (yeild / 100);
      let paymentMade = anualPayment * (paymentM / 100);
      let underPaid = anualPayment - paymentMade;
      let equityIncrease = (underPaid / (val * (1 + annualApperciation / 100))) * 100;
      let investorProceedsFromSale = index === year ? (val * investorEquityStake) / 100 : null;
      if (index !== year) {
        sumOfPaymentMade += paymentMade;
      }

      const tableDataArrayData = {
        year: index,
        homeStatus: index === year ? "Sold" : "Owned",
        homeValue: val,
        InvestorEquityStake: investorEquityStake,
        AnnualPaymentOwed:
          index === year ? 0 : "$" + Math.round(anualPayment).toLocaleString("en-US"),
        PaymentMade: index === year ? 0 : paymentMade,
        UnderPaid: index === year ? 0 : underPaid,
        EquityIncrease: index === year ? 0 : equityIncrease,
        InvestorProceedsFromSale: investorProceedsFromSale,
        CommulativePayback: Math.round(sumOfPaymentMade)
      };

      tableDataArray.push(tableDataArrayData);
      setTableDataState(tableDataArray);
    }
  }, [
    data,
    appraisesVal,
    investment,
    paymentM,
    yeild,
    year,
    annualApperciation,
    percentOfValueSold
  ]);

  //Calculation for Outputs
  useEffect(() => {
    setPropertySellsFor(appraisesVal * Math.pow(1 + annualApperciation / 100, year));
  }, [appraisesVal, annualApperciation, year]);

  useEffect(() => {
    if (tableDataState[0] !== undefined) {
      setInvestorEntitledTo(
        tableDataState[tableDataState.length - 1].InvestorEquityStake.toFixed(2)
      );
      let lastHomeValue = tableDataState[tableDataState.length - 1].homeValue;
      let lastInvestorEquityStake = tableDataState[tableDataState.length - 1].InvestorEquityStake;

      setPayoutFromSale(Math.round(lastHomeValue * (lastInvestorEquityStake / 100)));
    }

    setTotalAnnualPaymentsMade(
      Math.round(tableDataState.reduce((a, v) => (a = a + v.PaymentMade), 0))
    );
  }, [tableDataState]);

  useEffect(() => {
    setAPR((annualApperciation + yeild).toFixed(2));
  }, [annualApperciation, yeild]);

  useEffect(() => {
    setTotalPaybackToInvestors(Math.round(payoutFromSale + totalAnnualPaymentsMade));
  }, [payoutFromSale, totalAnnualPaymentsMade]);

  //calculation data for the modal
  const [modalCalculationData, setModalCalculationData] = useState([]);

  const modalToggleAction = (modalType, index, finalValue) => {
    let title = null;
    let description = null;
    let financeFormula = null;
    switch (modalType) {
      case "HomeValue":
        title = `Estimated Home Value: Year ${index}`;
        description =
          "Note that in practice, we determine a property's estimated home value by adjusting the initial appraised value by the percentage that the region's home price index has changed since the time of the appraisal.";
        financeFormula = {
          value1: `$${Math.round(tableDataState[index - 1].homeValue).toLocaleString("en-US")}`,
          value2: "1.06"
        };
        break;
      case "InvestorEquityStack":
        title = `Investor Equity Stake: Year ${index}`;
        financeFormula = {
          value1: `${tableDataState[index - 1].InvestorEquityStake.toFixed(2)}%`,
          value2: `${tableDataState[index - 1].EquityIncrease.toFixed(3)}%`
        };
        break;
      case "PaymentOwed":
        title = `Payment owed during year: Year ${index}`;
        financeFormula = {
          value1: `$${Math.round(tableDataState[index].homeValue).toLocaleString("en-US")}`,
          value2: `${tableDataState[index].InvestorEquityStake.toFixed(2)}%`,
          value3: `${yeild}%`
        };
        break;
      case "CashPaymentMade":
        title = `Cash payment made: Year ${index}`;
        description =
          "Note that in practice, each year, you can choose to pay any percent you want of the amount you owe. For example, you make the full payment one year, you make 20% of the payment in the next year and you make none of the payment in the next year. This just impacts whether and how much the equity sharing percentage increases.";
        financeFormula = {
          value1: `${tableDataState[index].AnnualPaymentOwed}`,
          value2: `${paymentM}%`
        };
        break;
      case "UnderpaidAmount":
        title = `Underpaid amount: Year ${index}`;
        financeFormula = {
          value1: `${tableDataState[index].AnnualPaymentOwed}`,
          value2: `$${str_into_num(Math.round(tableDataState[index].PaymentMade))}`
        };
        break;
      case "EquityIncrease":
        title = `Equity increase: Year ${index}`;
        financeFormula = {
          value1: `$${Math.round(tableDataState[index].UnderPaid).toLocaleString("en-US")}`,
          value2: `$${Math.round(tableDataState[index + 1].homeValue).toLocaleString("en-US")}`
        };
        break;
      case "InvestorProceedsFromSale":
        title = `Investor proceeds from sale: Year ${index}`;
        financeFormula = {
          value1: `$${Math.round(tableDataState[index].homeValue).toLocaleString("en-US")}`,
          value2: `${tableDataState[index].InvestorEquityStake.toFixed(2)}%`
        };
        break;
      case "CumulativePayback":
        title = `Cumulative payback: Year ${index}`;
        let paymentAllMadeArr = [];
        for (let i = 0; i <= index; i++) {
          if (i !== year) {
            paymentAllMadeArr.push(`$${str_into_num(Math.round(tableDataState[i].PaymentMade))}`);
          } else {
            paymentAllMadeArr.push(
              `$${str_into_num(Math.round(tableDataState[index].InvestorProceedsFromSale))}`
            );
          }
        }
        financeFormula = paymentAllMadeArr;
        break;
      case "PropertySellsFor":
        title = `Estimated Home Value: Year ${year}`;
        financeFormula = {
          value1: `$${Math.round(tableDataState[year - 1].homeValue).toLocaleString("en-US")}`,
          value2: "1.06"
        };
        break;
      case "InvestorEntitledTo":
        title = `Investor Equity Stake: Year ${year}`;
        financeFormula = {
          value1: `${tableDataState[year - 1].InvestorEquityStake.toFixed(2)}%`,
          value2: `${tableDataState[year - 1].EquityIncrease.toFixed(3)}%`
        };
        break;
      case "PayoutFromSale":
        title = `Investor proceeds from sale: Year ${year}`;
        financeFormula = {
          value1: `$${Math.round(tableDataState[year].homeValue).toLocaleString("en-US")}`,
          value2: `${tableDataState[year].InvestorEquityStake.toFixed(2)}%`
        };
        break;
      case "TotalPaybackToInvestors":
        title = `Cumulative payback: Year ${year}`;
        let paymentAllMadeArr2 = [];
        for (let i = 0; i <= year; i++) {
          if (i !== year) {
            paymentAllMadeArr2.push(`$${str_into_num(Math.round(tableDataState[i].PaymentMade))}`);
          } else {
            paymentAllMadeArr2.push(
              `$${str_into_num(Math.round(tableDataState[year].InvestorProceedsFromSale))}`
            );
          }
        }
        financeFormula = paymentAllMadeArr2;
        break;
      default:
        break;
    }

    // toggle();

    const modalCalculationData = {
      index,
      year,
      modalType,
      title,
      description,
      financeFormula,
      finalValue
    };
    setModalCalculationData(modalCalculationData);
  };

  //end calculation data for the modal

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      See calculation
    </Tooltip>
  );

  const backwardAndForwardHandler = (sliderInitValue, setSliderValueFunction) => {
    setTimeout(() => {
      setIsSliderAnimate(true);
      setSliderValueFunction(sliderInitValue + (sliderInitValue * 20) / 100);
      setTimeout(() => {
        setSliderValueFunction(sliderInitValue - (sliderInitValue * 20) / 100);
        setTimeout(() => {
          setIsSliderAnimate(false);
        }, 1000);
      }, 1000);
    }, 2000);
  };

  //Slider animation backward and forward
  useEffect(() => {
    if (isTutorialModalOpen === true) {
      let sliderInitValue = 0;

      switch (tutorialModalInitialStep) {
        case 0:
          sliderInitValue = appraisesVal;
          backwardAndForwardHandler(sliderInitValue, setSliderAppraisesVal);
          break;
        case 1:
          sliderInitValue = percentOfValueSold;
          backwardAndForwardHandler(sliderInitValue, setSliderPercentOfValueSold);
          break;
        case 2:
          sliderInitValue = paymentM;
          backwardAndForwardHandler(sliderInitValue, setSliderPaymentM);
          break;
        case 3:
          sliderInitValue = annualApperciation;
          backwardAndForwardHandler(sliderInitValue, setSliderAnnualApperciation);
          break;
        case 4:
          sliderInitValue = year;
          backwardAndForwardHandler(sliderInitValue, setSliderYear);
          break;
        case 5:
          sliderInitValue = yeild;
          backwardAndForwardHandler(sliderInitValue, setSliderYield);
          break;
        default:
          break;
      }
    }
  }, [isTutorialModalOpen, tutorialModalInitialStep]);

  return (
    <div className="equity-graph">
      <ReactTooltip />

      <div className="equity-graph-wrapper">
        <div className="main_equity text-center">
          <TutorialComponent
            investerSteps={InvesterSteps}
            ownerStep={Owner_steps}
            tab={tab}
            setTabs={setTab}
            current="homepage"
            componentType="class"
            isTutorialModalOpen={isTutorialModalOpen}
            setIsTutorialModalOpen={setIsTutorialModalOpen}
            tutorialModalInitialStep={tutorialModalInitialStep}
            setTutorialModalInitialStep={setTutorialModalInitialStep}
          />
          <div className="cash-tab mt-4">
            <div className="slider-title">
              <div className="InputBoxesTitle">
                <h4 className="text-left mt-5 mb-3">Assumptions</h4>
              </div>
            </div>
            <div className={"graph-slider " + dragging} style={{ marginTop: "10px" }}>
              <div
                className={`${TutorialClass(
                  tab,
                  "owner_appraised",
                  "invester_appraised"
                )} inner_div`}>
                <div className="refinance-input range-vertical equity-input e-input-3">
                  <div className="equity-input-text eit-2">
                    <h6> {showHeadings(tab, "Home value", "House's appraised value")} </h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value">
                            $
                            {isSliderAnimate === true && tutorialModalInitialStep === 0
                              ? str_into_num(sliderAppraisesVal)
                              : str_into_num(appraisesVal)}
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="What the house in question appraises for."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(0);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <InputRange
                    step={25000}
                    maxValue={2000000}
                    minValue={50000}
                    formatLabel={(value) =>
                      `$${value.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0
                      })} `
                    }
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 0
                        ? sliderAppraisesVal
                        : appraisesVal
                    }
                    onChange={(value) => {
                      setappraisesVal(value);
                    }}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>
              <div
                className={`${TutorialClass(
                  tab,
                  "owner_percent_sold",
                  "invester_percent_sold"
                )} inner_div`}>
                <div className="refinance-input range-vertical equity-input e-input-3">
                  <div className="equity-input-text eit-2">
                    <h6>
                      {" "}
                      {showHeadings(tab, "Percent of value sold", "Percent of value invested")}{" "}
                    </h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value">
                            {isSliderAnimate === true && tutorialModalInitialStep === 1
                              ? str_into_num(sliderPercentOfValueSold)
                              : str_into_num(percentOfValueSold)}
                            %
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="What the house in question appraises for."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(1);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <InputRange
                    step={1}
                    maxValue={55}
                    minValue={0}
                    formatLabel={(value) => `${value}% `}
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 1
                        ? sliderPercentOfValueSold
                        : percentOfValueSold
                    }
                    onChange={(value) => {
                      setPercentOfValueSold(value);
                    }}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>
              <div
                className={`${TutorialClass(
                  tab,
                  "Percent_of_payment_made_with_cash",
                  "invester_Percent_of_payment_made_with_cash"
                )} inner_div`}>
                <div className="refinance-input range-vertical equity-input e-input-3">
                  <div className="equity-input-text eit-2">
                    <h6>
                      {showHeadings(
                        tab,
                        "Percent of payments you make with cash",
                        "Percent of payments owner makes with cash"
                      )}{" "}
                    </h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value">
                        {isSliderAnimate === true && tutorialModalInitialStep === 2
                          ? str_into_num(sliderPaymentM)
                          : paymentM}{" "}
                        %
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="e.g. if owner sells 20% of $1m-appraised house, they get $200k."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(2);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <InputRange
                    step={1}
                    maxValue={100}
                    minValue={0}
                    formatLabel={(value) => `${value}% `}
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 2
                        ? str_into_num(sliderPaymentM)
                        : paymentM
                    }
                    onChange={(value) => setPaymentM(value)}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>
              <div
                className={`${TutorialClass(
                  tab,
                  "appreciation_owner",
                  "invester_appreciation"
                )} inner_div`}>
                <div className="refinance-input range-horizontal equity-input">
                  <div className="equity-input-text">
                    <h6>
                      {" "}
                      {showHeadings(
                        tab,
                        "Annual appreciation",
                        "Annualized appreciation scenario"
                      )}{" "}
                    </h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value">
                        {isSliderAnimate === true && tutorialModalInitialStep === 3
                          ? str_into_num(sliderAnnualApperciation)
                          : annualApperciation}{" "}
                        %
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="What percent, on average, the house appreciates per year."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(3);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <InputRange
                    step={1}
                    maxValue={15}
                    minValue={-10}
                    formatLabel={(value) => `${value}% `}
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 3
                        ? str_into_num(sliderAnnualApperciation)
                        : annualApperciation
                    }
                    onChange={(value) => setannualApperciation(value)}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>
              <div
                className={`${TutorialClass(
                  tab,
                  "owner_sale_refi",
                  "invester_sale_refi"
                )} inner_div`}>
                <div className="refinance-input range-vertical equity-input e-input-2">
                  <div className="equity-input-text eit-2">
                    {" "}
                    <h6>
                      {" "}
                      {showHeadings(
                        tab,
                        "Year you sell property or buy out investors",
                        "Year owner sells property or refinances"
                      )}{" "}
                    </h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value" style={{ minWidth: 24 }}>
                        {isSliderAnimate === true && tutorialModalInitialStep === 4
                          ? sliderYear
                          : year}
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="Years till the owner sells the property or buys out investors."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(4);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <InputRange
                    step={1}
                    maxValue={10}
                    minValue={0}
                    formatLabel={(value) => `${value} `}
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 4 ? sliderYear : year
                    }
                    onChange={(value) => yieldHanlder(value)}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>

              <div className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                <div className="refinance-input range-vertical equity-input e-input-3">
                  <div className="equity-input-text eit-2">
                    <h6>Yield %</h6>
                    <div className="sliders-data">
                      <div className="slider-chart-value">
                        {isSliderAnimate === true && tutorialModalInitialStep === 5
                          ? sliderYeild.toFixed(2)
                          : yeild.toFixed(2)}{" "}
                        %
                      </div>
                      <div
                        className="slider-tooltip"
                        data-tip="Determined by auction, this is the % of investors' equity's value the homeowner owes annually with cash or additional equity."
                        data-effect="solid"
                        data-place="top"
                        onClick={() => {
                          setIsTutorialModalOpen(true);
                          setTutorialModalInitialStep(5);
                        }}>
                        <svg
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.52352 5.87136V5.64407C3.52352 4.90795 3.97821 4.51823 4.43287 4.20429C4.87671 3.90119 5.32048 3.51149 5.32048 2.79703C5.32048 1.8011 4.51945 1 3.52352 1C2.5276 1 1.72656 1.8011 1.72656 2.79703"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.52077 8.45923H3.53051"
                            stroke="#4C506B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <InputRange
                    step={0.1}
                    maxValue={12}
                    minValue={0}
                    formatLabel={(value) => `${str_into_float_num(value)} % `}
                    value={
                      isSliderAnimate === true && tutorialModalInitialStep === 5
                        ? sliderYeild
                        : yeild
                    }
                    onChange={(value) => setyield(value)}
                    onChangeStart={(value) => {
                      addDragStart();
                    }}
                    onChangeComplete={(value) => {
                      removeDragStart();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="financial-table">
              <div className="InputBoxesTitle">
                <h4 className="text-left mt-5 mb-3">Annual Results</h4>
              </div>
              <Table className="graph-table table-responsive" bordered responsive="lg">
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Home status</th>
                    <th>Home value</th>
                    <th>Investor equity stake</th>
                    <th>Payment owed during year</th>
                    <th>Cash payment made</th>
                    <th>Underpaid</th>
                    <th>Equity increase</th>
                    <th>Investor proceeds from sale</th>
                    <th>Cumulative payback</th>
                  </tr>
                </thead>
                <tbody>
                  {tableDataState
                    ? tableDataState.map((e, index) => {
                        return (
                          <tr key={index}>
                            <td>{index}</td>
                            <td>
                              <span
                                className={
                                  e.homeStatus === "Owned"
                                    ? "graph-status-tag"
                                    : "graph-sold-status-tag"
                                }>
                                {e.homeStatus}
                              </span>
                            </td>
                            <td>
                              <OverlayTrigger placement="top" overlay={renderTooltip}>
                                {index === 0 ? (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      setIsTutorialModalOpen(true);
                                      setTutorialModalInitialStep(0);
                                    }}>
                                    ${Math.round(e.homeValue).toLocaleString("en-US")}
                                  </span>
                                ) : (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "HomeValue",
                                        index,
                                        `$${Math.round(e.homeValue).toLocaleString("en-US")}`
                                      );
                                    }}>
                                    ${Math.round(e.homeValue).toLocaleString("en-US")}
                                  </span>
                                )}
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger placement="top" overlay={renderTooltip}>
                                {index === 0 ? (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      setIsTutorialModalOpen(true);
                                      setTutorialModalInitialStep(1);
                                    }}>
                                    {e && e.InvestorEquityStake && e.InvestorEquityStake.toFixed(2)}
                                    %
                                  </span>
                                ) : (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "InvestorEquityStack",
                                        index,
                                        `${e.InvestorEquityStake.toFixed(2)}%`
                                      );
                                    }}>
                                    {e && e.InvestorEquityStake && e.InvestorEquityStake.toFixed(2)}
                                    %
                                  </span>
                                )}
                              </OverlayTrigger>
                            </td>
                            <td>
                               {index === year ? (
                                "-"
                              ) : (
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction("PaymentOwed", index, e.AnnualPaymentOwed);
                                    }}>
                                    {e.AnnualPaymentOwed}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td>
                              {index === year ? (
                                "-"
                              ) : (
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "CashPaymentMade",
                                        index,
                                        `$${Math.round(e.PaymentMade).toLocaleString("en-US")}`
                                      );
                                    }}>
                                    {"$" + str_into_num(Math.round(e.PaymentMade))}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td>
                              {index === year ? (
                                "-"
                              ) : (
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "UnderpaidAmount",
                                        index,
                                        `$${Math.round(e.UnderPaid).toLocaleString("en-US")}`
                                      );
                                    }}>
                                    {"$" + Math.round(e.UnderPaid).toLocaleString("en-US")}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td>
                              {index === year ? (
                                "-"
                              ) : (
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "EquityIncrease",
                                        index,
                                        `${e.EquityIncrease && e.EquityIncrease.toFixed(3)}%`
                                      );
                                    }}>
                                    {e && e.EquityIncrease && e.EquityIncrease.toFixed(3)+"%"}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </td>
                            <td>
                              {index === year ? (
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "InvestorProceedsFromSale",
                                        index,
                                        `$${str_into_num(Math.round(e.InvestorProceedsFromSale))}`
                                      );
                                    }}>
                                    $
                                    {e &&
                                      e.InvestorProceedsFromSale &&
                                      str_into_num(Math.round(e.InvestorProceedsFromSale))}
                                  </span>
                                </OverlayTrigger>
                              ) : null}
                            </td>
                            <td>
                              <OverlayTrigger placement="top" overlay={renderTooltip}>
                                {index === year ? (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "CumulativePayback",
                                        index,
                                        e.CommulativePayback + e.InvestorProceedsFromSale
                                      );
                                    }}>
                                    $
                                    {Math.round(
                                      e.CommulativePayback + e.InvestorProceedsFromSale
                                    ).toLocaleString("en-US")}
                                  </span>
                                ) : (
                                  <span
                                    className="cal-link"
                                    onClick={() => {
                                      toggle();
                                      modalToggleAction(
                                        "CumulativePayback",
                                        index,
                                        e.CommulativePayback
                                      );
                                    }}>
                                    ${e.CommulativePayback.toLocaleString("en-US")}
                                  </span>
                                )}
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })
                    : false}
                </tbody>
              </Table>
            </div>

            <div className="chart-container">
              <div className="InputBoxesTitle">
                <h4 className="text-left mt-5 mb-3">Summary Figures</h4>
              </div>
              <div className="row">
                <div className="">
                  <div
                    className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                    <div className="refinance-input range-vertical equity-input e-input-3">
                      <div className="equity-input-text eit-2">
                        <h6>Property sells for</h6>
                      </div>
                      <h3 className="text-left font-weight-normal">
                        <span
                          className="blue-text-link clickable-link"
                          onClick={() => {
                            toggle();
                            modalToggleAction(
                              "PropertySellsFor",
                              year,
                              `$${Math.round(propertySellsFor).toLocaleString("en-US")}`
                            );
                          }}>
                          ${Math.round(propertySellsFor).toLocaleString("en-US")}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                    <div className="refinance-input range-vertical equity-input e-input-3">
                      <div className="equity-input-text eit-2">
                        <h6>Investor entitled to</h6>
                      </div>
                      <h3 className="text-left font-weight-normal">
                        <span
                          className="blue-text-link clickable-link"
                          onClick={() => {
                            toggle();
                            modalToggleAction(
                              "InvestorEntitledTo",
                              null,
                              tableDataState[year].InvestorEquityStake
                            );
                          }}>
                          {investorEntitledTo}%
                        </span>{" "}
                        <span className="output-sub-text">of sale price at time of sale</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                    <div className="refinance-input range-vertical equity-input e-input-3">
                      <div className="equity-input-text eit-2">
                        <h6>{showHeadings(tab, "Payout from sale", "Proceeds from sale")}</h6>
                      </div>
                      <h3 className="text-left font-weight-normal">
                        <span
                          className="blue-text-link clickable-link"
                          onClick={() => {
                            toggle();
                            modalToggleAction(
                              "PayoutFromSale",
                              year,
                              `$${str_into_num(
                                Math.round(tableDataState[year].InvestorProceedsFromSale)
                              )}`
                            );
                          }}>
                          ${str_into_num(payoutFromSale)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                  <div className="refinance-input range-vertical equity-input e-input-3">
                    <div className="equity-input-text eit-2">
                      <h6>
                        {showHeadings(tab, "Total annual payments made", "Total annual payments")}
                      </h6>
                    </div>
                    <h3 className="text-left font-weight-normal">
                      ${str_into_num(totalAnnualPaymentsMade)}
                    </h3>
                  </div>
                </div>
                <div className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                  <div className="refinance-input range-vertical equity-input e-input-3">
                    <div className="equity-input-text eit-2">
                      <h6>
                        {showHeadings(
                          tab,
                          "Total payback to investors",
                          "Total return on investment"
                        )}
                      </h6>
                    </div>
                    <h3 className="text-left font-weight-normal">
                      <span
                        className="clickable-link blue-text-link"
                        onClick={() => {
                          toggle();
                          modalToggleAction(
                            "TotalPaybackToInvestors",
                            year,
                            totalPaybackToInvestors
                          );
                        }}>
                        ${str_into_num(totalPaybackToInvestors)}
                      </span>
                    </h3>
                  </div>
                </div>
                <div className={`${TutorialClass(tab, "owner_yield", "invester_yield")} inner_div`}>
                  <div className="refinance-input range-vertical equity-input e-input-3">
                    <div className="equity-input-text eit-2">
                      <h6>{showHeadings(tab, "Implied APR", "Implied IRR")}</h6>
                    </div>
                    <h3 className="text-left font-weight-normal">{APR}%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalculationModal
        isShowing={isShowing}
        hide={toggle}
        modalCalculationData={modalCalculationData}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setTutorialModalInitialStep={setTutorialModalInitialStep}
        modalToggleAction={modalToggleAction}
      />
    </div>
  );
};

export default NewEquity;
