import React, { useEffect, useState } from 'react'
import { NavLink, Redirect } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import moment from 'moment';

import { get_image_full_url } from "../../utills/helper";
import { str_into_num } from "../../utills/string-into-number";

import "../../components/auctions/auctions.css";

export default function AuctionListTable(props) {

  const [redirect, setredirect] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState();

  useEffect(() => {
    if (props.tableData !== undefined) {
      setTableData(props.tableData);
    }
    if (props.totalPageCount !== undefined) {
      setTotalPageCount(props.totalPageCount);
    }
  }, [props]);




  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={get_image_full_url(
                  row.row.original.property && row.row.original.property.properties_images[0] &&
                  row.row.original.property.properties_images[0]['image']
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        }
      },
      {
        Header: "Amount Raising",
        accessor: "amount_raising",
        Cell: (row) => {
          return <span className='amount-raising-col'>${str_into_num(row.value)}</span>
        }
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          return (
            <>
              {row.value === "Success" ? (
                <span className="tag--active">Success</span>
              ) : row.value === "Fail" ? (
                <span className="tag--closed">Fail</span>
              ) : row.value === "Active" ? (
                <span className="tag--active">Active</span>
              ) : row.value === "Under_Review" ? (
                <span className="tag--inreview">Under Review</span>
              ) : row.value === "" ? (
                <span className="tag--closed">Fail</span>
              ) : (
                <span className="tag--inreview">{row.value}</span>
              )}
            </>
          );
        },
      },

      {
        Header: "Date Saved",
        accessor: "createdAt",
        Cell: (row) => {
          return (
            <>{moment(row.value).isValid() ? moment(row.value).format("MMM DD, YYYY") : "-"}</>
          );
        }
      }
    ],
    []
  );

  const data = tableData;


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = useTable(
    {
      manualPagination: true,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: totalPageCount ? Math.ceil(totalPageCount / 10) : null
    },
    usePagination
  );

  const { pageIndex } = state;

  useEffect(() => {
    if (props.setCurrentPage) {
      props.setCurrentPage(pageIndex);
    }
  }, [pageIndex, props]);


  if (redirect) {
    return <Redirect to={redirect} />;
  }



  return (
    <div>
      <div className="auction-table paymentowed-table paymentowed-table-2">

        {tableData.length > 0 && props.isLoading === false ? (
          <>
            <table {...getTableProps()} className="table table-responsive" responsive="lg">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <>
                  {page && page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => { row.original.status !== "Under_Review" && setredirect("/auction/" + row.original.code) }}>
                        {row.cells.map((cell) => {
                          return (
                            <td className="dash-tbl-col" {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </table>
            {totalPageCount > 10 && (
              <div className="region-pagination">
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}>
                  {"<<"}
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}>
                  Previous
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}>
                  Next
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}>
                  {">>"}
                </button>{" "}
                <span className="pagination-text">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
            )}
          </>
        ) : tableData.length === 0 && props.isLoading === false ? (
          <>
            <table {...getTableProps()} className="table table-responsive" responsive="lg">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                <tr>
                  <td colSpan="4">
                    <div className="table-empty-state">
                      <div className="empty-state-img">
                        <img
                          src={require("../../Images/empty-state-icon.svg")}
                          alt="empty state img"
                        />
                        <p>You haven't launched any auctions</p>
                        <div className="createauction-btn createauction-btn-2">
                          <NavLink to="/auctions/create">
                            <button type="button">Create Auction</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) :
          <div className="text-center py-5">
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        }
      </div>
    </div>
  )
}
