import React from "react";
import "./PropertyPage.css";
import Header from "../Component/Header/Header";
import HeroGallery from "./PropertyPage/HeroGallery";
import DetailPageTitle from "./PropertyPage/DetailPageTitle";
import Footer from "../Component/Footer/Footer";
import { connect } from "react-redux";
import { axios_getSinglePropertyByCode, axios_loadProperty } from "../utills/property-routes";
import { Image_URL } from "../config/api";
import "intro.js/introjs.css";
import "../Component/tutorial-component/tutorialcomponent.css";
import "../components/equitygraph.css";
import "../App.css";
import NewEquity from "../Component/NewEquity/NewEquity";
import { get_image_full_url } from "../utills/helper";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
 

class PropertyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      property: {},
      found: 0,
      propertyImagesArray: {},
      stepsEnabled: false,
      stepsEnabled_2: false,
      tab: 0
    };
  }
  componentDidMount() {
    this.setState({ found: 1 });
    // axios_loadProperty(this.props.match.params.id)
    //   .then((res) => {
    //     if (res.data.success) {
    //       if (res.data.property != null) {
    //         this.setState({ property: res.data.property });
    //         document.title = `${res.data.property.property_address} | Exuity`;

    //         if (Image_URL === "http://localhost:4000") {
    //           const property_img_url =
    //             res.data.property && res.data.property.images2 !== null
    //               ? res.data.property.images2.slice(1)
    //               : [];
    //           const property_img_2 = property_img_url && property_img_url.slice(0, -1);
    //           const imagesArray = property_img_2
    //             ? property_img_2 && property_img_2.split && property_img_2.split(",")
    //             : [];
    //           const checkArray = imagesArray && imagesArray[0] === "null" ? [] : imagesArray;
    //           this.setState({ propertyImagesArray: checkArray });
    //         } else {
    //           const imagesArray =
    //             res.data.property && res.data.property.images2
    //               ? res.data.property.images2 &&
    //                 res.data.property.images2.split &&
    //                 res.data.property.images2.split(",")
    //               : [];
    //           const checkArray = imagesArray && imagesArray[0] === "null" ? [] : imagesArray;
    //           this.setState({ propertyImagesArray: checkArray });
    //           // setOldFiles(checkArray);
    //         }

    //         this.setState({ found: 2 });
    //       } else {
    //         this.setState({ found: 0 });
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.setState({ found: 0 });
    //   });

    axios_getSinglePropertyByCode(this.props.match.params.id)
      .then((response) => {
        if (response.data.data === null) {
          this.props.history.push("/properties");
        }
        this.setState({ found: 2 });
        this.setState({ property: response.data.data });
        if (response.data.status === false) {
          toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT });
          this.props.history.push("/"); 
        }
      })
      .catch((error) => {
        this.setState({ found: 0 });
        console.log("oops something went wrong.", error);
        // toast.error("getting error on single property",{position:toast.POSITION.TOP_RIGHT})
      });}

  render() {
    return (
      <div>
        <div className="white-header">
          <Header />
        </div>
        {this.state.found === 2 ? (
          <div>
            {this.state.property && this.state.property.images !== "null" ? (
              <div className="HeroGrid">
                <div className="detailimages">
                  {console.log('gett imag arr', this.state.property.properties_images)}
                  {this.state.property && this.state.property.properties_images &&
                    this.state.property.properties_images.map((item, index) => (
                      <>
                      {console.log('gett image arr ite', get_image_full_url(item.image))}
                        {index === 4 ? (
                          <div className={"HeroGridImg" + (index + 1)} key={`heroimg-${item.id}`}>
                            <img src={get_image_full_url(item.image)} alt="HeroGrid" />
                            <div className="more-btn">
                              <HeroGallery
                                imagesList={this.state.property.properties_images}
                              />
                            </div>
                          </div>
                        ) : index > 4 ? null : (
                          <div className={"HeroGridImg" + (index + 1)} key={`heroimg-${item.id}`}>
                            <img src={get_image_full_url(item.image)} alt="HeroGrid" />
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            ) : (
              <div className="mt-5 pt-5"></div>
            )}

            <div className="detailpage">
              <DetailPageTitle
                property={this.state && this.state.property}
                user={this.props && this.props.user}
                isAuthenticated={this.props && this.props.isAuthenticated}
              />
            </div>

            <div className="Calculation-Inputs">
              <NewEquity />
            </div>
          </div>
        ) : (
          <div>
            {this.state.found === 0 ? (
              <div className="HeroGrid">
                <p>Property not found</p>
              </div>
            ) : (
              <div className="HeroGrid text-center pt-5">
                <div className="spinner-grow text-warning" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <p>Loading. Please wait...</p>
              </div>
            )}
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user
});
export default connect(mapStatetoProps, { axios_loadProperty })(PropertyPage);
