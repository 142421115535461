import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_getIssuanceOwnerList=( page, limit, callback)=>{
    axios
    .get(`issuance/issuanceowner/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getIssuanceInvesterList=( page, limit, callback)=>{
    axios
    .get(`issuance/issuanceInvester/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getIssuanceIParticipated=(page, limit, callback)=>{
    axios
    .get(`issuance/getIssuanceIParticipated/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getIssuanceByCode=(code, callback)=>{
    axios
    .get(`issuance/issuanceByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_issuancesInvestorByCode=(code, callback)=>{
    axios
    .get(`issuance/issuancesInvestorByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}