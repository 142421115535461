import * as atypes from "./types";
import { axios_addAuction, axios_addBid } from "../../utills/auction-routes";
import { toast } from "react-toastify";

export const saveAuction =
  (property_id, amount_raising, max_yield, contract_length, start_date) =>
  async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING,
    });

    axios_addAuction(
      property_id,
      amount_raising,
      max_yield / 100,
      contract_length,
      start_date
    )
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message,{position:toast.POSITION.TOP_RIGHT})
          setTimeout(() => {
            window.location.href = "/auctions/";
          }, 2000);
        } else {
          toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
        }
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.", err);
        toast.error("Something went wrong.Please try again",{position:toast.POSITION.TOP_RIGHT})
      });
  };
export const saveBid =
  (auctionId, amountToInvest, centOnDollar) => async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING,
    });

    axios_addBid(auctionId, amountToInvest, centOnDollar)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message,{position:toast.POSITION.TOP_RIGHT})
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
        toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
        }
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.",err.response.data.message);
        toast.error(err.response.data.message,{position:toast.POSITION.TOP_RIGHT})
      });
  };
