import React from "react";
import "../../components/Dashboard.css";
import "../../components/properties/properties.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Head from "../../Component/Head";
import DropdownButton from "react-bootstrap/DropdownButton";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OwnerPropertyTable from "../../Component/owner-property-table/OwnerPropertyTable";

export default function OwnerProperties() {
  return (
    <>
      <Head title={"Exuity | Owner | Properties"} />
      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Your Properties</h4>
            </div>
            <div className="createauction-btn createauction-btn-2 properties">
              <DropdownButton id="dropdown-basic-button" title="Claim property">
                <h3>Things to do:</h3>
                <Tabs defaultActiveKey="onwer" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="onwer" title="Onwer">
                    <ul className="dropdown-items">
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/tick-square.svg")}
                              alt="Tick Square"
                            />
                          </span>
                          <span>Add Property</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Confirm Identity</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Submit Application Form</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/tick-square.svg")}
                              alt="Tick Square"
                            />
                          </span>
                          <span>Have Onboarding Call</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Get Appraisal Scheduled</span>
                        </a>
                      </li>
                      <li className="last-item">
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/question-square.svg")}
                              alt="Question Square"
                            />
                          </span>
                          <span>Launch Auction</span>
                        </a>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="investor" title="Investor">
                    <ul className="dropdown-items">
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/tick-square.svg")}
                              alt="Tick Square"
                            />
                          </span>
                          <span>Add Property</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Confirm Identity</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Submit Application Form</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/tick-square.svg")}
                              alt="Tick Square"
                            />
                          </span>
                          <span>Have Onboarding Call</span>
                        </a>
                      </li>
                      <li>
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/cross-square.svg")}
                              alt="Cross Square"
                            />
                          </span>
                          <span>Get Appraisal Scheduled</span>
                        </a>
                      </li>
                      <li className="last-item">
                        <a href="0">
                          <span>
                            <img
                              src={require("../../Images/question-square.svg")}
                              alt="Question Square"
                            />
                          </span>
                          <span>Launch Auction</span>
                        </a>
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </DropdownButton>
              {/* </NavLink> */}
            </div>
          </div>

          <div className="row">
            <div className="">
              <Sidebar />
            </div>
            <div className="DashContent">
              <OwnerPropertyTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
