import React from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "../PropertyPage.css";

import Editable from "react-bootstrap-editable";
import ReactTooltip from "react-tooltip";

import MasterModal from "../../Component/master-modal/MasterModal";
import { CalculationData } from "../db";

class CalculatorInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 6.5,
      value2: 10,
      value3: 70,
      popModal: null
    };
  }

  openModal = Modal => {
    switch (Modal) {
      case "modal1":
        return this.setState({ popModal: CalculationData.modal1 });
      case "modal2":
        return this.setState({ popModal: CalculationData.modal2 });
      case "modal3":
        return this.setState({ popModal: CalculationData.modal3 });
      case "modal4":
        return this.setState({ popModal: CalculationData.modal4 });
      case "modal5":
        return this.setState({ popModal: CalculationData.modal5 });
      case "modal6":
        return this.setState({ popModal: CalculationData.modal6 });
      case "modal7":
        return this.setState({ popModal: CalculationData.modal7 });
      case "modal8":
        return this.setState({ popModal: CalculationData.modal8 });
      case "modal9":
        return this.setState({ popModal: CalculationData.modal9 });
      case "modal10":
        return this.setState({ popModal: CalculationData.modal10 });
      default:
        break;
    }
  };

  removeModal = () => {
    this.setState({
      popModal: null
    });
  };

  renderModal() {
    const { popModal } = this.state;
    if (popModal) {
      return (
        <MasterModal
          visible={true}
          removeModal={this.removeModal}
          heading={popModal.heading}
          paragraph={popModal.paragraph}
        />
      );
    }
  }

  anchor(modal) {
    return (
      <button

        onClick={() => this.openModal(modal)}
        data-tooltip="More Info"
        data-placement="right"
      >
        ?
      </button>
    );
  }

  render() {
    return (
      <div className="InputSection">
        <div className="dp-section-title">
          <h4>My Properties</h4>
        </div>
        <div className="modal-wrapper">{this.renderModal()}</div>
        <div className="InputBoxes">
          <ReactTooltip />
          <div className="InputBox active">
            <div className="inputTitleGroup2">
              <h6 className="input-title">Investment Amount</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal1")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <span className="Input-property">
              <Editable
                ajax={null}
                alwaysEditing={false}
                className="edit-textfield"
                disabled={false}
                editText="."
                id={null}
                initialValue="$100,000"
                isValueClickable={false}
                label={null}
                mode="inline"
                onSubmit={null}
                onValidated={null}
                options={null}
                placement="top"
                showText
                type="textfield"
                validate={null}
              />
            </span>
          </div>
          <div className="InputBox active">
            <div className="inputTitleGroup2">
              <h6 className="input-title">Current Appriased Value</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal2")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <span className="Input-property">
              <Editable
                ajax={null}
                alwaysEditing={false}
                className="edit-textfield"
                disabled={false}
                editText="."
                id={null}
                initialValue="$500,000"
                isValueClickable={false}
                label={null}
                mode="inline"
                onSubmit={null}
                onValidated={null}
                options={null}
                placement="top"
                showText
                type="textfield"
                validate={null}
              />
            </span>
          </div>
          <div className="InputBox active">
            <div className="inputTitleGroup2">
              <h6 className="input-title">Dividend Yield</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal3")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <span className="Input-property">
              <Editable
                ajax={null}
                alwaysEditing={false}
                className="edit-textfield"
                disabled={false}
                editText="."
                id={null}
                initialValue="4.5%"
                isValueClickable={false}
                label={null}
                mode="inline"
                onSubmit={null}
                onValidated={null}
                options={null}
                placement="top"
                showText
                type="textfield"
                validate={null}
              />
            </span>
          </div>
          <div className="InputBox">
            <div className="inputTitleGroup2">
              <h6 className="input-title">Underpayment Penalty</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal4")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <span className="Input-property">6.5%</span>
          </div>
        </div>
        <form className="calculatorInputsGroup">
          <div className="InputRange">
            <div className="inputTitleGroup">
              <h6 className="input-title">Sale date, years from now</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal6")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <InputRange
              maxValue={14}
              minValue={0}
              value={this.state.value2}
              onChange={value => this.setState({ value2: value })}
              onChangeComplete={value => console.log(value)}
            />
          </div>
          <div className="InputRange">
            <div className="inputTitleGroup">
              <h6 className="input-title">Percent of interest paid</h6>
              <span
                className="box-toltip"
                onClick={() => this.openModal("modal7")}
                data-tip="More Info"
                data-effect="solid"
                data-place="right"
              >
                ?
              </span>
            </div>
            <InputRange
              maxValue={100}
              minValue={0}
              formatLabel={value => `${value} %`}
              value={this.state.value3}
              onChange={value => this.setState({ value3: value })}
              onChangeComplete={value => console.log(value)}
            />
          </div>
        </form>
        <div className="OutputBoxes">
          <div className="Calculator-OutputBoxesGroup">
            <div className="OnputBox">
              <div className="inputTitleGroup2">
                <h6 className="input-title">Implied IRR</h6>
                <span
                  className="box-toltip"
                  onClick={() => this.openModal("modal9")}
                  data-tip="More Info"
                  data-effect="solid"
                  data-place="right"
                >
                  ?
                </span>
              </div>
              <span className="Onput-property">11.3%</span>
            </div>
            <div className="OnputBox">
              <div className="inputTitleGroup2">
                <h6 className="input-title">Investor entitled to</h6>
                <span
                  className="box-toltip"
                  onClick={() => this.openModal("modal10")}
                  data-tip="More Info"
                  data-effect="solid"
                  data-place="right"
                >
                  ?
                </span>
              </div>
              <span className="Onput-property">
                20%{" "}
                <span className="output-Investor">
                  (of property sale value)
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatorInputs;
