import React from "react";
import Sidenav from "../../components/Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Head from "../../Component/Head";
import InvestorPropertyTable from "../../Component/investor-property-table/InvestorPropertyTable";
import "../../components/properties/properties.css";

export default function InvestorProperties() {
  return (
    <div className="Dashboard">
      <Head title={"Exuity | Investor | Properties"} />

      <div className="DashboardGroup">
        <div className="auction-navbar">
          <div className="dashTitle">
            <h4>Properties</h4>
          </div>
          <Sidenav />
        </div>
        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>
          <div className="DashContent pt-0 pt-lg-5">
            <div className="AuctionPage">
              <InvestorPropertyTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
