import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import waitOneSecond from "../utills/waitOneSecond";
import { setToast } from "../store/actions/toast";
import store from "../store/store";
import { NavigationContext } from "../hooks/createNavigationContext";
import ScrollToTop from "../Component/scroll-to-top/ScrollToTop";

//auth not required routes
import PropertyPage from "../components/PropertyPage";
import SearchPage from "../Page/SearchPage/SearchPage";
import ModelPage from "../Page/model-page/ModelPage";

//Auth and user related routes
import SignInForm from "../login-signup-screens/SignInForm";
import SignUpForm from "../login-signup-screens/SignUpForm";
import ForgotPassword from "../login-signup-screens/ForgotPass";
import Activation from "../login-signup-screens/Activation";
import ResetPassword from "../login-signup-screens/ResetPass";

//Logged in user routes
import Dashboard from "../components/Dashboard";
import Calculator from "../Page/Calculator/Calculator";
//owner tabs routes
import OwnerProperties from "../Page/owner-properties/OwnerProperties";
import AddProperty from "../components/properties/AddProperty";
import EditProperty from "../components/properties/EditProperty";
import PropertyDetailPage from "../components/properties/DetailsPage";
import OwnerAuctions from "../Page/owner-auctions/OwnerAuctions";
import Auction from "../components/auctions/Auction";
import AuctionCreate from "../components/auctions/AuctionCreate";
import IssuancesOwner from "../components/issuances/IssuancesOwner";
import IssuancesOwnerDetails from "../components/issuances/IssuancesOwnerDetails";
import PaymentOwedOwner from "../components/PaymentOwed/PaymentOwedOwner";
import PayamentOwedDetails from "../components/PaymentOwed/PayamentOwedDetails";
import PaymentMade from "../components/PaymentMade/PaymentMade";
import PayamentMadeDetail from "../components/PaymentMade/PaymentMadeDetails";
import InvestmentsReceivedOwner from "../components/InvestmentReceived/InvestmentsReceivedOwner";

//investor tabs routes
import InvestorProperties from "../Page/investor-properties/InvestorProperties";
import InvestorTableDetails from "../components/properties/InvestorTableDetails";
import InvestorAuctions from "../Page/investor-auctions/InvestorAuctions";
import AuctionInvestorDetail from "../components/auctions/AuctionInvestorDetail";
import IssuancesInvestor from "../components/issuances/IssuanceInvestor";
import IssuancesInvestorDetails from "../components/issuances/IssuancesInvestorDetails";
import PaymentOwedInvestor from "../components/PaymentOwed/PaymentOwedInvestor";
import PaymentsInvestor from "../components/InvestmentReceived/PaymentsInvestor";
import PaymentOwedInvestorDetails from "../components/PaymentOwed/PaymentOwedInvestorDetails";
import PaymentMadeDetails from "../components/PaymentMade/PaymentMadeDetails";
import PaymentReceivedInvestorDetail from "../components/InvestmentReceived/PaymentReceivedInvestorDetail";

//account tabs routes
import Profile from "../Page/Profile/profile";
import ProfileUpdate from "../Page/profile-update/ProfileUpdate";
import Identity from "../Page/Identity/Identity";
import Accreditation from "../Page/Accreditation/Accreditation";
import Taxinfo from "../Page/Taxinfo/Taxinfo";
import Balance from "../Page/Balance/Balance";
import SelectAccount from "../Page/SelectBankAccount/SelectBankAccount";

//404 Route - page Not found
import PageNotFound from "../Page/PageNotFound/PageNotFound";
import { toast } from "react-toastify";
import { getIsLoggedIn } from "../utills/helper";

var gap = null;

const requireLogin = (to, from, next) => {
  if (gap != null) {
    gap.pageview(to.location.pathname);
  }
  if (to.meta.auth) {
    if (getIsLoggedIn()) {
      next();
    } else {
      toast.error("You need to be logged in to access that.", {
        position: toast.POSITION.TOP_RIGHT
      })
      next.redirect("/");
    }
    // next.redirect("/?require_login=1");
  } else {
    next();
  }
};
const NotFound = ({ error }) => (
  <div>
    <h1>Not found.</h1>
    <p>{error}</p>
  </div>
);

const Loading = () => (
  <div>
    <div id="loader" />
  </div>
);

const Router = () => {
  const [activeNav, setActiveNav] = useState("a");
  const investerProperty = window.location.href.includes("propertiesinvestor");

  useEffect(() => {
    if (investerProperty) {
      setActiveNav("b");
    }
  }, [investerProperty]);

  return (
    <NavigationContext.Provider value={[activeNav, setActiveNav]}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <GuardProvider guards={[requireLogin, waitOneSecond]} loading={Loading} error={NotFound}>
          {/* <Switch> */}
          <ScrollToTop>
            <Switch>
              {/* auth not required routes */}
              <GuardedRoute path="/" component={SignInForm} exact={true} />
              <GuardedRoute path="/property/:id" component={PropertyPage} meta={{ auth: false }} />
              <GuardedRoute path="/search" component={SearchPage} meta={{ auth: false }} />
              <GuardedRoute path="/model" component={ModelPage} meta={{ auth: false }} />

              {/* Auth and user related routes */}
              <GuardedRoute path="/signup" component={SignUpForm} />
              <GuardedRoute path="/forgotpassword" component={ForgotPassword} />
              <GuardedRoute path="/resetpassword" component={ResetPassword} />
              <GuardedRoute path="/activate-account" component={Activation} />

              {/* Logged in user routes */}
              <GuardedRoute path="/dashboard" component={Dashboard} meta={{ auth: true }} />
              <GuardedRoute path="/calculator" component={Calculator} meta={{ auth: true }} />

              {/* owner tabs routes */}
              <GuardedRoute
                path="/properties"
                exact={true}
                component={OwnerProperties}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/properties/create" exact={true} component={AddProperty} />
              <GuardedRoute
                path="/edit/property/:id"
                exact={true}
                component={EditProperty}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/property-details/:code"
                component={PropertyDetailPage}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/auctions"
                exact={true}
                component={OwnerAuctions}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/auction/:id" component={Auction} meta={{ auth: true }} />
              <GuardedRoute
                path="/auctions/create"
                exact={true}
                component={AuctionCreate}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesowner"
                component={IssuancesOwner}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesowner-details/:code"
                component={IssuancesOwnerDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedowner"
                component={PaymentOwedOwner}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedowner-details/:code"
                component={PayamentOwedDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/paymentmade" component={PaymentMade} meta={{ auth: true }} />
              <GuardedRoute
                path="/paymentmadeowner-details/:code"
                component={PayamentMadeDetail}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/investmentsreceivedowner"
                component={InvestmentsReceivedOwner}
                meta={{ auth: true }}
              />
              {/* owner tabs routes  end*/}

              {/* investor tabs routes start */}
              <GuardedRoute
                path="/propertiesinvestor"
                component={InvestorProperties}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/propertiesinvestor-details/:code"
                component={InvestorTableDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/auctioninvestor"
                exact={true}
                component={InvestorAuctions}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/AuctionInvestor-details/:code"
                component={AuctionInvestorDetail}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesinvestor"
                component={IssuancesInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesinvestor-details/:code"
                component={IssuancesInvestorDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedinvestor"
                component={PaymentOwedInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentsinvestor"
                component={PaymentsInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedinvestor-details/:code"
                component={PaymentOwedInvestorDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentmade-details/:code"
                component={PaymentMadeDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentsReceived-details/:code"
                component={PaymentReceivedInvestorDetail}
                meta={{ auth: true }}
              />
              {/* investor tabs routes end */}

              {/* account tabs routes start */}
              <GuardedRoute path="/profile" component={Profile} meta={{ auth: true }} />
              <GuardedRoute
                path="/profile-update"
                component={ProfileUpdate}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/identity" component={Identity} meta={{ auth: true }} />
              <GuardedRoute path="/accreditation" component={Accreditation} meta={{ auth: true }} />
              <GuardedRoute path="/taxinfo" component={Taxinfo} meta={{ auth: true }} />
              <GuardedRoute path="/balance" component={Balance} meta={{ auth: true }} />
              <GuardedRoute
                path="/select-account"
                component={SelectAccount}
                meta={{ auth: true }}
              />
              {/* account tabs routes end */}

              <GuardedRoute path="*" component={PageNotFound} meta={{ auth: false }} />
            </Switch>
          </ScrollToTop>
          {/* </Switch> */}
        </GuardProvider>
      </BrowserRouter>
    </NavigationContext.Provider>
  );
};

export default Router;
