import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import moment from "moment";
import { useHistory } from "react-router";
import { axios_loadAuction } from "../../utills/auction-routes";
import { str_into_float_num, str_into_num } from "../../utills/string-into-number";
import { toast } from "react-toastify";

export default function AuctionInvestorDetail() {
  const history = useHistory();
  const [investorDetail, setInvestorDetail] = useState();
  const { code } = useParams();

  useEffect(() => {
    axios_loadAuction(code)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            setInvestorDetail(response.data);
          }
        }
      })
      .catch((error) => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
        console.log("getting error on investor auction", error);
        history.push("/auctions");
      });
  }, [code, history]);

  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Auction Investor Detail</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {investorDetail != null ? (
                  <div>
                    {console.log("investron detaills",investorDetail.data)}
                    <ul>
                      <li>
                        <b>Amount Raising: </b> $
                        {str_into_num(investorDetail.data.amount_raising)}
                      </li>
                      <li>
                        <b>Start Date: </b>{" "}
                        {moment(investorDetail.data.start_date).isValid()
                          ? moment(investorDetail.data.start_date).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Contract Length: </b> {investorDetail.data.contract_length}{" "}
                        {investorDetail.data.contract_length === 1 ? " year" : " years"}
                      </li>
                      <li>
                        <b>Max Yield: </b>{" "}
                        {str_into_float_num(investorDetail.data.max_yield * 100)}%
                      </li>
                      <li>
                        <b>Created At: </b>{" "}
                        {moment(investorDetail.data.createdAt).isValid()
                          ? moment(investorDetail.data.createdAt).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>End At: </b>{" "}
                        {moment(investorDetail.data.end_date).isValid()
                          ? moment(investorDetail.data.end_date).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Property Address: </b> {investorDetail.data.property.property_address}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
