import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./router/routes";
import store from "./store/store";
import { Provider } from "react-redux";
import Drift from "react-driftjs";
import { hydrate, render } from 'react-dom';
// css styles
import "./index.css";
import "./App.css"
import "./Component/Header/header.css";
import "./circular/stylesheet.css";

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <Router />
        <Drift appId="y7se3rdertmz" />
        <ToastContainer />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <Router />
        <Drift appId="y7se3rdertmz" />
        <ToastContainer />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
