import React, { useCallback } from "react";
import "./accreditation.css";
import FontAwesomeIcon from "react-fontawesome";
import { useDropzone } from "react-dropzone";
// import Toast from "../layout/Toast";

const DocDropZone = ({ setFiles }) => {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
    },
    [setFiles]
  );

  function sizeValidation(file) {
    const fileLimit = 1024 * 1024 * 5;
    if (file.size > fileLimit) {
      return {
        code: "size-too-large",
        message: `size is larger than 5mb `
      };
    }

    return null;
  }
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept:
      "application/pdf,application/rtf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    onDrop: handleDrop,
    id: "docpicker",
    multiple: true,
    validator: sizeValidation
  });

  const fileRejectionItems = (fileRejections) => {
    return (
      <div className="alert alert-danger mt-5 mb-5" role="alert">
        {fileRejections[0].errors[0].code === "file-invalid-type" && (
          <h6>Only pdf ,doc,docs files are allowed</h6>
        )}
        {fileRejections[0].errors[0].code === "size-too-large" && (
          <div> {fileRejections[0].errors[0].message}</div>
        )}
      </div>
    );
  };

  return (
    <div className="main-upload text-center" {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="uploading-wrapper">
        <img src={require("../../Images/file-upload-icon.svg")} alt="File upload Icon" />
        {/* <FontAwesomeIcon size={"3x"} name="fa-solid fa-upload" /> */}
        <div className="drag-file-text">
          <h6 className="mt-3 font-weight-bold">or drag document here</h6>
          <button type="button" className="btn btn-outline-secondary mt-3 font-weight-bold">
            Browse Files
          </button>
        </div>
        {fileRejections && fileRejections.length > 0 && (
          <div>{fileRejectionItems(fileRejections)}</div>
        )}
      </div>
    </div>
  );
};

export default DocDropZone;
