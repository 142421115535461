import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { axios_issuancesInvestorByCode } from "../../utills/issuance-routes";
import { str_into_num } from "../../utills/string-into-number";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function IssuancesInvestorDetails() {
  const history = useHistory();
  const [issuancesInvestorData, setIssuancesOwnerData] = useState();
  const { code } = useParams();

  const getInvestorCallback = useCallback(
    (response) => {
      if (response.status === 200) {
        if (response.data.status === true) {
          setIssuancesOwnerData(response.data.data)
        }
      }
      if (response.data.data === null) {
        toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
        history.push("/issuancesinvestor");
      }
    },
    [history]
  );
  useEffect(() => {
    axios_issuancesInvestorByCode(code, getInvestorCallback);
  }, [code, getInvestorCallback]);

  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Issuances Details You Participate In</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="PropertyCardGroup">
                {issuancesInvestorData != null ? (
                  <div>
                    <ul>
                      <li>
                        <b>Amount Invested: </b> $
                        {str_into_num(issuancesInvestorData.amount_invested)}
                      </li>
                      <li>
                        <b>Issuance Date: </b>{" "}
                        {moment(issuancesInvestorData.created_at).isValid()
                          ? moment(issuancesInvestorData.created_at).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                      <li>
                        <b>Contract Length: </b> {issuancesInvestorData.issuance.contract_length}{" "}
                        {issuancesInvestorData.issuance.contract_length === 1 ? " year" : " years"}
                      </li>
                      <li>
                        <b>Issuance Status: </b> {issuancesInvestorData.issuance.status}
                      </li>
                      <li>
                        <b>Issuance Amount: </b> $
                        {str_into_num(issuancesInvestorData.issuance.amount)}{" "}
                      </li>
                      <li>
                        <b>Issuance Yield: </b>{" "}
                        {(issuancesInvestorData.issuance.yield * 100).toFixed(2)}%
                      </li>
                      <li>
                        <b>Appraised Value: </b> $
                        {str_into_num(issuancesInvestorData.issuance.appraised_value)}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
