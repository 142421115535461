import * as atypes from "./types";
// import axios from '../..//utills/axios-server';
//import { setAlert } from "./alert";
// import { setToast } from "./toast";
import {
  axios_addProperty,
  axios_loadProperty,
  axios_loadProperties,
  axios_createProperty
} from "../../utills/property-routes";
import { toast } from "react-toastify";
//import { useHistory } from "react-router-dom";

export const createProperty = (property_address) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });

  axios_createProperty(property_address)
    .then((response) => {
      if (response.data.status) {
        toast.success("Property added successfully", {
          position: toast.POSITION.TOP_RIGHT
        });

        setTimeout(() => {
          window.location.href = "/properties";
        }, 2000);
      } else {
        toast.error("All fields are required.Kindly check and resubmit",{position:toast.POSITION.TOP_RIGHT})
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{postion:toast.POSITION.TOP_RIGHT})
    });
};

export const saveProperty =
  (
    property_type,
    mortage_balance,
    desired_cash,
    estimated_property_value,
    property_address,
    builtup_area,
    bathrooms,
    bedrooms,
    lat,
    lng,
    uploaded_photos
  ) =>
  async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING
    });

    axios_addProperty(
      property_type,
      mortage_balance,
      desired_cash,
      estimated_property_value,
      property_address,
      builtup_area,
      bathrooms,
      bedrooms,
      lat,
      lng,
      uploaded_photos
    )
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setTimeout(() => {
            window.location.href = "/properties";
          }, 2000);
        } else {
          toast.error("All fields are required. Kindly check and resubmit",{
            position:toast.POSITION.TOP_RIGHT
          })
          
        }
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.", err);
        toast.error(err.response.data.message,{postion:toast.POSITION.TOP_RIGHT})
      });
  };

export const loadProperty = (id) => async (dispatch) => {
  axios_loadProperty(id)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: atypes.LOAD_USER,
          payload: response
        });
      } else {
        dispatch({
          type: atypes.AUTH_ERROR
        });
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{postion:toast.POSITION.TOP_RIGHT})
      dispatch({
        type: atypes.AUTH_ERROR
      });
      // localStorage.removeItem("userToken")
    });
};

export const deleteProperty = () => (dispatch) => {};

export const startLoading = () => (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });
};

export const stopLoading = () => (dispatch) => {
  dispatch({
    type: atypes.STOP_LOADING
  });
};

export const updateProperty =
  ({ newPassword, confirmPassword, token }) =>
  async (dispatch) => {};

export const loadProperties = (page) => async (dispatch) => {
  axios_loadProperties(page)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: atypes.LOAD_USER,
          payload: response
        });
      } else {
        dispatch({
          type: atypes.AUTH_ERROR
        });
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error(err.response.data.message,{postion:toast.POSITION.TOP_RIGHT})
      dispatch({
        type: atypes.AUTH_ERROR
      });
      // localStorage.removeItem("userToken")
    });
};
