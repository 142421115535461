import React from "react";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { saveProperty } from "../../store/actions/property";
import store from "../../store/store";
import { setToast } from "../../store/actions/toast";
import { delayedSubmit } from "../../store/reducers/helper";
import Head from "../../Component/Head";
import "../../Component/SearchProperty/searchproperty.css";
import "../properties/addProperty.css";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

class AddProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      isVisible: false,
      isSubmitted: false,
      submitting: false,
      property_type: "",
      mortage_balance: "",
      desired_cash: "",
      estimated_property_value: "",
      property_address: "",
      property_address_parts: "",
      builtup_area: "",
      bathrooms: "",
      bedrooms: "",
      address: "",
      title: "",
      lat: "",
      lng: "",
      uploaded_photos: [],
      draggingItem: null,
      dragOverItem: null,
      tab: "Property",
    };
    this.selectPhoto = this.selectPhoto.bind(this);
    this.removeIt = this.removeIt.bind(this);
  }

  placeholderHandler = () => {
    const DOM_Placholder = document.querySelector(
      ".addressfilter__single-value"
    );
    let text = "Enter property address...";
    if (DOM_Placholder) {
      DOM_Placholder.innerText = text;
    }
  };
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    this.placeholderHandler();
  }
  openProperty() {
    this.setState({
      isVisible: true,
      isSubmitted: true,
    });
  }
  findproperties() { }

  basicInfo() {
    if (this.state.property_address !== "") {
      this.setState({ submitting: true });
      delayedSubmit(500).then(() => {
        store
          .dispatch(
            saveProperty(
              "",
              0,
              0,
              0,
              this.state.property_address,
              0,
              0,
              0,
              this.state.lat,
              this.state.lng,
              ""
            )
          )
          .then((res) => { })
          .catch((err) => {
            console.log("Oops! Something went wrong.", err);
            this.setState({ submitting: false });
          });
      });
    } else {
      if (this.state.property_address === "") {
        toast.error("Please provide Property Address", {
          position: toast.POSITION.TOP_RIGHT
        })

      }
    }
  }
  propertyCost() {
    if (
      this.state.mortage_balance !== "" &&
      this.state.desired_cash !== "" &&
      this.state.estimated_property_value !== ""
    ) {
      this.setState({ isSubmitted: !this.state.isSubmitted });
    } else {
      if (this.state.mortage_balance === "") {
        toast.error("Please provide Mortage Balance", { position: toast.POSITION.TOP_RIGHT })
      } else if (this.state.desired_cash === "") {
        toast.error("Please provide Desired Cash", { position: toast.POSITION.TOP_RIGHT })
      } else if (this.state.estimated_property_value === "") {
        toast.error("Please provide Estimated Property Value", { position: toast.POSITION.TOP_RIGHT })
      }
    }
  }
  property_address() {
    this.setState({ submitting: true });

    if (
      this.state.builtup_area !== "" &&
      this.state.bathrooms !== "" &&
      this.state.bedrooms !== ""
    ) {

    } else {
      console.log(this.state.submitting);
      this.setState({ submitting: false });
      console.log(this.state.submitting);
      if (this.state.builtup_area === "") {
        toast.error("Please provide Built Up Area", { position: toast.POSITION.TOP_RIGHT })
      } else if (this.state.bathrooms === "") {
        toast.error("Please provide bathrooms", { position: toast.POSITION.TOP_RIGHT })
      } else if (this.state.bedrooms === "") {
        toast.error("Please provide bedrooms", { position: toast.POSITION.TOP_RIGHT })
      }
    }
  }
  selectPhoto(e) {
    this.refs.fileUploader.click();
  }

  removeIt(i) {
    var uploaded_photos = this.state.uploaded_photos;
    uploaded_photos.splice(i, 1);
    this.setState({ uploaded_photos: uploaded_photos });
  }



  handleSelect = async (value) => {

    const results = await geocodeByAddress(value);

    const latLng = await getLatLng(results[0]);
    this.setState({ lat: latLng.lat })
    this.setState({ lng: latLng.lng })

    this.setState({ address: value });
    this.setState({ property_address: value })

  };


  render() {

    return (
      <div className="Dashboard" id="Dashboard">
        <Head title={`Exuity | Claim a property as yours`} />
        <div className="DashboardGroup">
          <div className="row">
            <div className="dashTitle-2">
              <h4>Claim a Property</h4>
            </div>
            <div className="">
              <Sidebar />
            </div>
            <div className="DashContent">
              {this.props.user ? (
                <div className="AddProperty">
                  {this.state.isVisible ? (
                    this.state.isSubmitted ? (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              Hi, {this.props.user.first_name}! Let us know
                              which property you own that you'd like to consider
                              getting cash out of.
                            </h5>
                          </div>
                          <div className="AddProperty-steps">
                            <ul>
                              <li className="active-tick">Basic Info</li>
                              <li className="active-tick">Property Cost</li>
                              <li className="active">Property Area</li>
                            </ul>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <div className="AddProperty-form">
                              <ul className="AddProperty-inputs">
                                <li>
                                  <label className="input-label">
                                    Built Up Area
                                  </label>
                                  <input
                                    type="number"
                                    name="Area"
                                    placeholder="sq.ft."
                                    value={this.state.builtup_area}
                                    onChange={(e) =>
                                      this.setState({
                                        builtup_area: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Bedrooms
                                  </label>
                                  <input
                                    type="number"
                                    name="bedrooms"
                                    placeholder="1"
                                    value={this.state.bedrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        bedrooms: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Bathrooms
                                  </label>
                                  <input
                                    type="number"
                                    name="bathrooms"
                                    placeholder="1"
                                    value={this.state.bathrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        bathrooms: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="Ap-submitted-btn">
                              <button
                                onClick={() => this.property_address()}
                                readOnly={this.state.submitting}
                              >
                                {this.state.submitting
                                  ? "Please Wait..."
                                  : "Add Property"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              Hi, {this.props.user.first_name}! Let’s get
                              started listing your space.
                            </h5>
                          </div>
                          <div className="AddProperty-steps">
                            <ul>
                              <li className="active-tick">Basic Info</li>
                              <li className="active">Property Cost</li>
                              <li>Property Area</li>
                            </ul>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <div className="AddProperty-form">
                              <ul className="AddProperty-inputs">
                                <li>
                                  <label className="input-label">
                                    Mortage Balance ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="balance"
                                    placeholder="0.00"
                                    value={this.state.mortage_balance}
                                    onChange={(e) =>
                                      this.setState({
                                        mortage_balance: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Desired Cash ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="Cash"
                                    placeholder="0.00"
                                    value={this.state.desired_cash}
                                    onChange={(e) =>
                                      this.setState({
                                        desired_cash: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Estimated Property Value ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="Value"
                                    placeholder="0.00"
                                    value={this.state.estimated_property_value}
                                    onChange={(e) =>
                                      this.setState({
                                        estimated_property_value:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="Ap-submitted-btn">
                              <button onClick={() => this.propertyCost()}>
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="AddPropertyGroup">
                      <div className="AddProperty-ls">
                        <div className="AddProperty-t">
                          <h5>
                            Hi, {this.props.user.first_name}! Let us know which
                            property you own that you'd like to consider getting
                            cash out of.
                          </h5>
                        </div>

                        <div className="AddProperty-illustration">
                          <img
                            src={require("../../Images/AddProperty-illustration.png")}
                            alt="Add Property Home Illustration"
                          />
                        </div>
                      </div>
                      <div className="AddProperty-rs align-items-start">
                        <div className="AddProperty-rs-Group">
                          <div className="AddProperty-form">
                            <ul className="AddProperty-inputs">
                              <li>
                                <label className="input-label">
                                  Property Address
                                </label>


                                <PlacesAutocomplete
                                  value={this.state.address}
                                  onChange={(e) => this.setState({ address: e })}
                                  onSelect={this.handleSelect}
                                  searchOptions={{
                                    strictBounds: false,
                                    types: ["street_address", "premise"],
                                    componentRestrictions: {
                                      country: ["usa"]
                                    }
                                  }}>
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div id="preload" style={{ position: "relative" }}>
                                      <input
                                        style={{
                                          padding: 15,
                                          width: "100%",
                                          height: 74,
                                          border: 0,
                                          fontFamily: "CircularStd",
                                          fontWeight: 400,
                                          fontSize: "18px",
                                          lineHeight: "22px",
                                          background: "#FFFFFF",
                                          outline: "none",
                                          boxShadow: "0px 6px 24px rgb(0 0 0 / 6%)",
                                          borderRadius: "0 10px 10px 10px"
                                        }}
                                        className={"property_address_input"}
                                        {...getInputProps({
                                          placeholder: "Address?"
                                        })}
                                      />
                                      {this.state.address && (
                                        <div
                                          className="search-cross"
                                          onClick={() => this.setState({ address: "" })}
                                          style={{
                                            position: "absolute",
                                            top: 15,
                                            right: 17,
                                            fontSize: 27,
                                            cursor: "pointer"
                                          }}>
                                          <span>x</span>
                                        </div>
                                      )}

                                      <div className="search-dropdowns">
                                        {loading ? (
                                          <div className="search-loading-state">
                                            <div className="search-loading-text">Loading...</div>
                                            <div className="spinning-loader">
                                              <img src={"../../Images/spinning-loader.gif"} width={25} height={25} alt="Spinner" />
                                            </div>
                                          </div>
                                        ) : null}

                                        {suggestions && suggestions.map((suggestion) => {
                                          const style = {
                                            backgroundColor: suggestion.active ? "#deebff" : "#fff",
                                            padding: "8px",
                                            margin: "8px",
                                            width: "100%",
                                            height: 50,
                                            fontFamily: "Circular Std Book",
                                            marginBottom: 5,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            border: 1,
                                            borderRadius: "10px",
                                            borderColor: "grey"
                                          };

                                          return (
                                            <div {...getSuggestionItemProps(suggestion, { style })}>
                                              {suggestion.description}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>

                              </li>
                            </ul>
                          </div>
                          <div className="Ap-submitted-btn text-left pt-4">
                            <button onClick={() => this.basicInfo()}>
                              Claim Property
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {
  loadUser,
  startLoading,
  logout,
  setToast,
  saveProperty,
})(AddProperty);
