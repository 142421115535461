import * as atypes from "./types";
import axios from "../..//utills/axios-server";
import {
  axios_loadUser,
  axios_loginUser,
  axios_registerUser,
  axios_updateUser
} from "../../utills/axios-routes";
import Cookies from "js-cookie";

// for toast
import { toast } from "react-toastify";
toast.configure(); // toast-configuration method,

export const loginUser = (email, password, rememberme) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });

  axios_loginUser(email, password)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: atypes.LOGIN_SUCCESS,
          payload: { token: response.data.data.token }
        });
        axios.defaults.headers.common["x-auth-token"] = response.data.data.token;
        localStorage.setItem("userToken", response.data.data.token);
        dispatch(loadUser());

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });

        if (rememberme) {
          Cookies.set("email", email, { expires: 14 });
          Cookies.set("password", password, {
            expires: 14
          });
          Cookies.set("rememberme", true, { expires: 14 });
        } else {
          Cookies.set("email", "");
          Cookies.set("password", "");
          Cookies.set("rememberme", "");
        }
      } else {
        console.log("error msg is => ", response.data.message);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);

      if (err.response.status === 401) {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
};

export const loadUser = () => async (dispatch) => {
  axios_loadUser()
    .then((response) => {
      if (response.data.status) {
        // localStorage.setItem("userphoto", response.data.data.photo);
        dispatch({
          type: atypes.LOAD_USER,
          payload: response.data.data
        });
      } else {
        setTimeout(() => {
          dispatch({
            type: atypes.SET_TOAST,
            payload: []
          });

          // localStorage.removeItem("userToken")
        }, 10000);
        dispatch({
          type: atypes.AUTH_ERROR
        });
      }
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      dispatch({
        type: atypes.AUTH_ERROR
      });
      // localStorage.removeItem("userToken")
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userToken");
  dispatch({
    type: atypes.LOGOUT
  });
  window.location.href = "/";
};

export const startLoading = () => (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });
};

export const stopLoading = () => (dispatch) => {
  dispatch({
    type: atypes.STOP_LOADING
  });
};
export const isEmailRegisterd = (email) => async (dispatch) => {
  await axios
    .get("/api/auth/checkemail")
    .then((response) => {
      dispatch({
        type: atypes.LOAD_USER,
        payload: response.data
      });
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      dispatch({
        type: atypes.AUTH_ERROR
      });
    });
};

export const registerUser = (data) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });
  // const body = JSON.stringify({ fname, lname, email, password, username });
  // try {
  axios_registerUser(data)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: atypes.REGISTER_SUCCESS,
          payload: response.data
        });

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });

        setTimeout(() => {
          window.location.href = "/?signup=success";
          setTimeout(() => {
            toast.success("Please check your email for an email-confirmation link.", {
              position: toast.POSITION.TOP_RIGHT
            });
          }, 2000);
        }, 1000);
      } else {
        toast.warning(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      dispatch({
        type: atypes.STOP_LOADING
      });
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error("Somthing went wrong! Register Failed", {
        position: toast.POSITION.TOP_RIGHT
      });

      dispatch({
        type: atypes.STOP_LOADING
      });
      dispatch({
        type: atypes.REGISTER_FAIL
      });
    });
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING
  });

  axios_updateUser(data)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: atypes.REGISTER_SUCCESS,
          payload: response.data
        });

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
         window.location.href=("/profile")
      } else {
        toast.warning(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      dispatch({
        type: atypes.STOP_LOADING
      });
    })
    .catch((err) => {
      console.log("Oops! Something went wrong.", err);
      toast.error("Somthing went wrong! Register Failed", {
        position: toast.POSITION.TOP_RIGHT
      });

      dispatch({
        type: atypes.STOP_LOADING
      });
      dispatch({
        type: atypes.REGISTER_FAIL
      });
    });
};

export const resetPassword =
  ({ newPassword, confirmPassword, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const body = JSON.stringify({ newPassword, confirmPassword, token });
    // try {
    await axios
      .post("/api/auth/reset-password", body, config)
      .then((response) => {
        // dispatch({
        //   type: atypes.RESET_PASSWORD,
        //   payload: response.data
        // });
        if (response.data.found) {
          toast.success("Password reset successfully.Redirecting to Login page", {
            position: toast.POSITION.TOP_RIGHT
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        } else {
          toast.error("Somthing went wrong.Password not updated.Redirecting to Login page", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log("Oops! Something went wrong.", err);
        toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT });
        // dispatch({
        //   type: atypes.REGISTER_FAIL
        // });
      });
  };
