let API_URL;
export let Image_URL;
if (process.env.REACT_APP_ENV === "staging") {
  API_URL = "https://devapi.vessel.us/api";
  Image_URL = "https://devapi.vessel.us";
} else if (process.env.REACT_APP_ENV === "dev") {
  API_URL = "http://localhost:4000/api";
  // API_URL = "http://192.168.0.155:4000/api";
  Image_URL = "http://localhost:4000";
} else {
  API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000/api";
  Image_URL = "https://api.exuity.com";
}

export default API_URL;
