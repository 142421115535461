import React, { useEffect, useState } from 'react'
import Sidenav from '../../components/Sidenav'
import "../../components/Dashboard.css";
import "../../components/auctions/auctions.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Head from "../../Component/Head";
import { axios_auctionsIbided } from '../../utills/auction-routes';
import AuctionListTable from '../../Component/auction-list-table/AuctionListTable';

export default function InvestorAuctions() {

    const [isLoading, setIsLoading] = useState(true);
    const [auctionList, setAuctionList] = useState([])
    const [totalPageCount, setTotalPageCount] = useState()
    const [currentPage, setCurrentPage] = useState(0)


    useEffect(() => {
        setIsLoading(true)
        axios_auctionsIbided(currentPage, 10, getAuctionCallback);
    }, [currentPage])

    const getAuctionCallback = (response) => {
        if (response.status === 200) {
            if (response.data.status === true) {
                setTotalPageCount(response.data.data.count);
                setAuctionList(response.data.data.rows);

            }
        }
        setIsLoading(false)
    };


    return (
        <div className="Dashboard">
            <Head title={'Exuity | Investor | Auctions'} />
            <div className="DashboardGroup">
                <div className="auction-navbar">
                    <div className="dashTitle">
                        <h4>Auctions You're Watching</h4>
                    </div>
                    <Sidenav />
                </div>
                <div className="row">
                    <div className="auction-navbar1">
                        <Sidebar />
                    </div>
                    <div className="DashContent pt-0 pt-lg-5">
                        <div className="AuctionPage">
                            <AuctionListTable
                                isLoading={isLoading}
                                tableData={auctionList}
                                totalPageCount={totalPageCount}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
