import { toast } from 'react-toastify';
import axios from './axios-server';

export const axios_getPaymentOwed=( page, limit, callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedOwner/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}


export const getPaymentOwedInvestor=(callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedInvestor`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) =>{ 
        callback(response)})
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getPaymentOwedOwnerByCode=(code, callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedOwnerByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}

export const axios_getPaymentOwedInvestorByCode=(code, callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedInvestorByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong.", error);
        toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})
    });  
}