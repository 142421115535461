import React, { useState, useEffect, useCallback } from "react";
import Sidenav from "../../components/Sidenav";
import "./balance.css";
import Sidebar from "../../Component/Sidebar/Sidebar";

import { usePlaidLink } from "react-plaid-link";
import {
  exchangePublicToken,
  createTokenLink,
  getUserBalance,
  // widthDrawAmount,
  removeAccount,
} from "../../utills/balance-routes";
import { axios_loadUser } from "../../utills/axios-routes";
import { Redirect } from "react-router-dom";
import Head from "../../Component/Head";
import { Dropdown} from "react-bootstrap";

const Balance = () => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [redirect, setredirect] = useState(null);
  const [name, setName] = useState("");

  const getUserData = useCallback(async () => {
    const user = await axios_loadUser();
    setUserData(user.data.data);
  }, []);

  useEffect(() => {
    // You can await here
    getUserData();
    const createLinkToken = async () => {
      const response = await createTokenLink();
      const { link_token } = await response.data;
      setToken(link_token);
    };
    const getUserCurrentBalance = async () => {
      const user_Balance = await getUserBalance();
      const { balanes } = await user_Balance.data;
      setName(balanes && balanes.balances && balanes.name);
    };
    getUserCurrentBalance();
    createLinkToken();
  }, [getUserData]);

  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: async (public_token, metadata) => {
      // send public_token to server
      const accountId = metadata.accounts[0].id;
      if (public_token) {
        const response = await exchangePublicToken(public_token, accountId);
        const { status } = await response.data;
        if (status) {
          // navigate('/select-account')
          setredirect("/select-account");
        }
      }
    },
    onExit: (err, metadata) => {
      console.log(err, " err on exit");
      console.log(metadata, " metadeta on exit");
    },
  });
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const removeAccountHandler = () => {
    const dataOBJ = {
      bank_account_verified: "",
      account_type_id: "",
      stripe_bank_account_token: "",
      access_token_plaid: "",
    };
    removeAccount(dataOBJ, getUserData);
  };
  return (
    <div className="Dashboard">
      <Head title={"Exuity | Balance"} />
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <div className="dashTitle">
            <h4>
              {(userData && userData.bank_account_verified) ||
                (userData && userData.stripe_bank_account_token !== null) ||
                (userData && userData.access_token_plaid !== null)
                ? "Primary Account"
                : "Bank is not verified yet"}
              {userData && userData.bank_account_verified && (
                <span style={{ fontSize: 25, color: "#feb808" }}>
                  {" "}
                  : {name}{" "}
                </span>
              )}
            </h4>
            {userData && userData.bank_account_verified ? (
              <Dropdown>
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
              Account Settings
              </Dropdown.Toggle>
        
              <Dropdown.Menu>
                <Dropdown.Item href="/select-account"> Change Account Type</Dropdown.Item>
                <Dropdown.Item onClick={() => open()}> Change Bank</Dropdown.Item>
                <Dropdown.Item  onClick={() => removeAccountHandler()}>Remove Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            ) : (
              // <div className="div">
              //   {/* <button
              //     style={{ backgroundColor: "#000000" }}
              //     type="button"
              //     disabled={!ready}
              //     className="btn btn-secondary mt-3"
              //   >
              //     Change Bank
              //   </button>
              //   <NavLink to={"/select-account"}>
              //     <button
              //       style={{ backgroundColor: "#000000" }}
              //       type="button"
              //       disabled={!ready}
              //       className="btn btn-secondary mt-3"
              //     >
              //       Change Account
              //     </button>
              //   </NavLink> 

              // </div>
              <button
                style={{
                  backgroundColor: "#ffb600",
                  color: "black",
                  fontWeight: "bold",
                  borderColor: "#ffb600",
                }}
                type="button"
                onClick={() => open()}
                disabled={!ready}
                className="btn btn-secondary mt-3"
              >
                Verify Bank
              </button>
            )}
          </div>
          <Sidenav />
        </div>
        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>
          <div className="DashContent main">
            <div
              className="main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div className="box p-4">
                <h3 className="text-center">Withdraw Balance</h3>
                <form onSubmit={(e) => handleWithDraw(e)}>
                  <div className="row ">
                    <div className="col-lg-12 col-12">
                      <div className="mb-3">
                        <label htmlFor="propertyType" className="">
                          Amount
                        </label>
                        <input
                          required
                          type="number"
                          className=""
                          id="propertyType"
                          placeholder="e.g $1000"
                          value={amount}
                          onChange={(e) => setamount(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="status">Method</label>
                        <select
                          // value={status}
                          className="form-select"
                          value={method}
                          onChange={(e) => setMethod(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="check">Check</option>
                          <option value="ach">ACH</option>
                          <option value="wire">Wire</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        <button
                          style={{ backgroundColor: "#000000" }}
                          type="submit"
                          className="btn btn-secondary mt-3"
                        >
                          Withdraw Amount
                        </button>
                      </div>
                    </div>
                    {loading && (
                      <div className="col-12">
                        <div className="mb-3 d-flex justify-content-center align-items-center">
                          <div className="alert alert-success" role="alert">
                            Successfully Withdraw
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div> */}
              <div className="div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
