import React, { useEffect, useState } from "react";
import Head from "../../Component/Head";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Sidenav from "../Sidenav";
import { useParams } from "react-router-dom";
import { str_into_float_num } from "../../utills/string-into-number";
import moment from "moment";
import { axios_getPaymentsYouReceivedByCode } from "../../utills/paymentsInvestor-routes";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function PaymentReceivedInvestorDetail() {
  const history = useHistory();
  const [paymentReceivedData, setPaymentReceivedData] = useState();
  const { code } = useParams();

  useEffect(() => {
    const paymentYouRecievedCallback = (response) => {
      if (response.status === 200) {
        if (response.data.status === true) {
          setPaymentReceivedData(response.data.data);
        }
      }
      if (response.data.data === null) {
        toast.error(response.data.message,{position:toast.POSITION.TOP_RIGHT})
        history.push("/paymentsinvestor");
      }
    };
    axios_getPaymentsYouReceivedByCode(code, paymentYouRecievedCallback);
  }, [code, history]);

  return (
    <>
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Property"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Your received payment</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {paymentReceivedData != null ? (
                  <div>
                    <ul>
                      <li>
                        <b>Amount: </b> ${str_into_float_num(paymentReceivedData.amount)}
                      </li>
                      <li>
                        <b>Taxable As: </b> {paymentReceivedData.taxable_as}
                      </li>
                      <li>
                        <b>Home Value: </b> $
                        {str_into_float_num(paymentReceivedData.payments_owed.home_value)}
                      </li>
                      <li>
                        <b>Property Address: </b>
                        {
                          paymentReceivedData.payments_owed.issuance.auction.property
                            .property_address
                        }
                      </li>
                      <li>
                        <b>Created at: </b>
                        {moment(paymentReceivedData.created_at).isValid()
                          ? moment(paymentReceivedData.created_at).format("MMM DD, YYYY")
                          : "-"}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
