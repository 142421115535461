import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { loginUser } from "../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";

import "./Form.css";
import queryString from "query-string";

import { toast } from 'react-toastify';


import Cookies from "js-cookie";
import Head from "../Component/Head";
import { getIsLoggedIn } from "../utills/helper";
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


export default function SignInForm(props){
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userData = useSelector((state) => state.auth.isAuthenticated);

  const parsed = queryString.parse(window.location.search);
   
  useEffect(()=>{
    if(parsed.success==="1"){
      toast.success("Email successfully confirmed. Please log in.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  },[parsed.success])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const signup = queryParams.get("signup");
		if (signup) {
				toast.success("Please check your email for an email-confirmation link.",{position:toast.POSITION.TOP_RIGHT})
		}
  }, [])

  useEffect(() => {
    if (Cookies.get("Exuity_email") && Cookies.get("Exuity_password")) {
      setEmail(Cookies.get("Exuity_email"));
      setPassword(Cookies.get("Exuity_password"));
    }
  }, [setEmail, setPassword]);

  useEffect(() => {
    if (getIsLoggedIn() === true) {
      history.push("/properties");
    }
  }, [userData, history]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: email,
        password: password,
        success: false,
        rememberme: false
      }}
      onSubmit={async (values, { setFieldValue, setErrors, setSubmitting }) => {
        await sleep(1000);
        if (values.rememberme === true) {
          Cookies.set("Exuity_email", values.email);
          Cookies.set("Exuity_password", values.password);
        }

        dispatch(loginUser(values.email, values.password, 1))
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Email is required"),
        password: Yup.string()
          .required("Password is required")
          // .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      })}>
      {(formik) => {
        return (
          <Form className="form-wrapper">
            <Head title={"Exuity | Log in"} />
            <div className="brand">
              <a href="https://exuity.com">
                <img src={require("../Images/exuity-logo.svg")} alt="brand" />
              </a>
            </div>
            {!formik.values.success ? (
              <div className="form-box">
                <div className="form-text-wrapper">
                  <h1>Log In</h1>
                  <p>Log In to your Exuity account to move money in and out of real estate.</p>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <Field
                    name="email"
                    className={
                      formik.touched.email && formik.errors.email
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="email"
                    placeholder="Your Email Address"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="form-group pass-input">
                  <label className="password-labels" htmlFor="password">
                    <span>Password</span>
                    <span className="forgot-pass-link">
                      <Link to="/forgotpassword">Forgot password</Link>
                    </span>
                  </label>
                  <Field
                    name="password"
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Your Account Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  ) : null}
                </div>

                <div className="form-group remember-checkbox">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="Remember password"
                      name="rememberme"
                    />
                    <label className="custom-control-label" htmlFor="Remember password">
                      Remember me
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <button type="submit" className="blue-btn" disabled={formik.isSubmitting}>
                    {formik.isSubmitting === true ? "Please wait..." : "Log In"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    Don't have an account? <Link to="/signup">Create Account</Link>
                  </p>
                </div>
              </div>
            ) : (
              <div>Logged In Successfully. Redirecting</div>
            )}
            <div className="copyrights-text">
              <p>&copy; {new Date().getFullYear()}, Exuity</p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
