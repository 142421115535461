import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { updateUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../login-signup-screens/Form.css";

const EditProfileForm = (props) => {
  const dispatch = useDispatch();
  const initialFormValue = {
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    phoneno_country: props.user.phoneno_country,
    phoneno: props.user.phoneno,
    password: props.user.password,
    date_of_birth: props.user.date_of_birth,
    avatarPreview: props.user.photo,
    uploaded_photo: "",
    address: props.user.address,
    success: false
  };
  const [formInitialValue, setFormInitialValue] = useState(initialFormValue);

  // Get and set input values
  const onChangeHandler = (e) => {
    e.persist();
    setFormInitialValue((formInitialValue) => {
      return { ...formInitialValue, [e.target.name]: e.target.value };
    });
  };

  const onChange4PhoneNo = (phoneno, countryCode, formik) => {
    formik.setFieldValue("phoneno", phoneno.slice(countryCode.dialCode.length));
    formik.setFieldValue("phoneno_country", countryCode.dialCode);
  };

  const photoset = (e, formik) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        formik.setFieldValue("uploaded_photo", fileReader.result);
        formik.setFieldValue("avatarPreview", fileReader.result);
      }
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValue}
      onSubmit={async (values, actions) => {
        let data = {
          phoneno_country: values.phoneno_country,
          phoneno: values.phoneno,
          password: values.password !== "" ? values.password : "",
          date_of_birth: values.date_of_birth,
          avatarPreview: values.photo,
          uploaded_photo: values.uploaded_photo,
          address: values.address,
          success: false
        };
        Object.keys(data).forEach(function (key) {
          var value = data[key];
          if (typeof value === "undefined" || value === "") {
            data[key] = null;
          }
        });

        dispatch(updateUser(data));
      }}
      validationSchema={Yup.object({
        first_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your first name is required"),
        last_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your last name is required"),
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Email is required"),
        password: Yup.string()
          .nullable()
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      })}>
      {(formik, isSubmitting, setStatus, status) => {
        return (
          <Form className="form-wrapper">
            {!formik.values.success ? (
              <div className="form-box signup-form-box">
                <div className="form-group">
                  <label className="photo-labels mr-3" htmlFor="photo">
                    <span>Profile Photo</span>
                  </label>
                  <img
                    className="mb-3"
                    src={formik.values.avatarPreview}
                    width="100px"
                    height="100px"
                    alt="avatar Preview"
                  />

                  <Field
                    name="photo_"
                    onChange={(e) => photoset(e, formik)}
                    className={
                      formik.touched.photo && formik.errors.photo
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="file"
                    placeholder="Photo"
                  />
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                      name="first_name"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.first_name && formik.errors.first_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your First Name"
                      readOnly
                      disabled
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">{formik.errors.first_name}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                      name="last_name"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.last_name && formik.errors.last_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your Last Name"
                      readOnly
                      disabled
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">{formik.errors.last_name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <Field
                      name="email"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.email && formik.errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="email"
                      placeholder="Your Email Address"
                      readonly
                      disabled
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneno">Phone Number</label>

                    <PhoneInput
                      country={"us"}
                      value={formik.values.phoneno_country + formik.values.phoneno}
                      onChange={(phoneno, countryCode) =>
                        onChange4PhoneNo(phoneno, countryCode, formik)
                      }
                      inputClass="PhoneInputInput form-control pl-5"
                      enableAreaCodes={true}
                      enableAreaCodeStretch
                    />
                  </div>
                </div>
                <div className="form-group pass-input">
                  <label className="date_of_birth-labels" htmlFor="date_of_birth">
                    <span>Date of Birth</span>
                  </label>
                  <Field
                    type="date"
                    id="birthday"
                    name="date_of_birth"
                    onChange={(e) => onChangeHandler(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="address-labels" htmlFor="address">
                    <span>Address</span>
                  </label>
                  <Field
                    name="address"
                    default={""}
                    onChange={(e) => onChangeHandler(e)}
                    className={
                      formik.touched.address && formik.errors.address
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Your Address"
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="invalid-feedback">{formik.errors.address}</div>
                  ) : null}
                </div>

                <div className="form-group pass-input">
                  <label className="password-labels" htmlFor="password">
                    <span>Password</span>
                  </label>
                  <Field
                    name="password"
                    onChange={(e) => onChangeHandler(e)}
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Create New Account Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button type="submit" className="blue-btn" disabled={isSubmitting}>
                    {isSubmitting ? "Please wait..." : "Update"}
                  </button>
                </div>
              </div>
            ) : (
              <div>Profile is updated successfully</div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProfileForm;
