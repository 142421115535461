import React from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "../Dashboard.css";
import { Button } from "react-bootstrap";
import store from "../../store/store";
import { saveBid } from "../../store/actions/auction";
import { setToast } from "../../store/actions/toast";
import { connect } from "react-redux";
import { delayedSubmit } from "../../store/reducers/helper";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
class SubmitBidCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 20,
      value1: 2500,
      value2: 3,
      popModal: null,
      interest: false,
      visible: 0,
      submitting: false
    };
  }
  palceBid() {
    if (this.state.interest === true) {
      this.setState({ submitting: true });
      delayedSubmit(500).then(() => {
        store
          .dispatch(saveBid(this.props.auction.id, this.state.value1, this.state.value2))
          .then((res) => {
            this.setState({ submitting: false });
          })
          .catch((err) => {
            console.log("Oops! Something went wrong.", err);
            this.setState({ submitting: false });
          });
      });
    } else {
      if (this.state.interest !== true) {
        toast.error("Please accept terms",{position:toast.POSITION.TOP_RIGHT})
      }
    }
  }
  componentDidMount() {
    this.setState({ value2: this.props.auction.max_yield * 100 });
    this.setState({ visible: 1 });
  }

  render() {
    const auctionEndDate = moment(this.props.auction.end_date).format();
    const currentDate = moment().format();
    const checkDate = currentDate > auctionEndDate ? true : false;
    return (
      <div className="SubmitBidCard">
        {this.state.visible > 0 && this.props.timer > 0 ? (
          <div className="SubmitBidCardGroup">
            <div className="SubmitBidCardTitle">
              <h6 className="SubmitBidCardHeading">Submit a Bid</h6>
            </div>
            <div className="action-inputs">
              <div className="sb-slider">
                <div className="refinance-input range-horizontal equity-input action-ip-1">
                  <div className="equity-input-text">
                    <h6>Amount to Invest</h6>
                  </div>
                  <InputRange
                    step={500}
                    maxValue={this.props.auction.amount_raising}
                    minValue={2500}
                    formatLabel={(value) => `$${value.toLocaleString("en-US")} `}
                    value={this.state.value1}
                    onChange={(value) => this.setState({ value1: value })}
                    onChangeComplete={(value) => console.log(value)}
                    orientation="vertical"
                  />
                </div>
              </div>
              <div className="sb-input-2">
                <div className="sb-slider">
                  <div className="refinance-input equity-input action-ip-2">
                    <div className="equity-input-text">
                      <h6>Minimum Yield Required</h6>
                    </div>
                    <InputRange
                      step={0.01}
                      maxValue={this.props.auction.max_yield * 100}
                      minValue={0}
                      formatLabel={(value) => `${value} %`}
                      value={this.state.value2.toFixed(2)}
                      onChange={(value) => this.setState({ value2: value })}
                      onChangeComplete={(value) => console.log(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sbc-checkmark">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  value={this.state.interest}
                  defaultChecked={this.state.cashOut}
                  onChange={() => this.setState({ interest: !this.state.interest })}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  I have reviewed and I agree to the terms of the
                  <Link to="/"> Trust Agreement </Link> and{" "}
                  <Link to="/"> Equity Sharing Option Agreement.</Link>
                </label>
              </div>
            </div>
            <div className="sbc-buttons">
              <div className="sbc-btn">
                <Button
                  onClick={() => this.palceBid()}
                   disabled={this.state.submitting || checkDate}
                  >
                  {this.state.submitting ? "Please Wait..." : "Review Offer"}
                </Button>
                {checkDate && (
                  <div className="alert alert-danger mt-4" role="alert">
                    The auction is closed. You cannot bid on it.
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p>Bidding Time Over</p>
          </div>
        )}
      </div>
    );
  }
}
const mapStatetoProps = (state) => ({});
export default connect(mapStatetoProps, { setToast, saveBid })(SubmitBidCard);
