import React from "react";
import "./Dashboard.css";
import PropertyCard from "./properties/PropertyCard";
import AddProperty from "./DashboardPage/AddProperty";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../store/actions/auth";
import { axios_loadProperties } from "../utills/property-routes";
import store from "../store/store";
import { NavLink } from "react-router-dom";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
      properties: [],
    };
  }

  openProperty() {
    this.setState({
      isVisible: true,
    });
  }
  loadProperties() {
    axios_loadProperties(1, 4)
      .then((value) => {
        this.setProperties(value.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setProperties(properties) {
    this.setState({ properties: properties });
    if (properties.length > 0) {
      this.setState({
        found: 2,
      });
    } else {
      this.setState({
        found: 0,
      });
    }
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    this.setState({
      found: 1,
    });
    this.loadProperties();
  }
  render() {
    return (
      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <div className="row">
            {this.state.isVisible ? (
              <div className="DashContent">
                <AddProperty user={this.props.user} />
              </div>
            ) : (
              <div className="DashContent">
                <div className="dashTitle-bar">
                  <div className="dashTitle">
                    <h4>My Properties</h4>
                  </div>
                  <div className="addPropert-btn">
                    <NavLink to="/properties/create">
                      <button type="button">
                        <span>
                          <svg
                            className="btn-plusIcon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12px"
                            height="12px"
                          >
                            <path
                              opacity="0.749"
                              fill="rgb(255, 255, 255)"
                              d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"
                            />
                          </svg>
                        </span>
                        Add property
                      </button>
                    </NavLink>
                  </div>
                </div>
                {this.state.found === 2 ? (
                  <div className="PropertyCardGroup">
                    {this.state.properties.map(function (i) {
                      return (
                        <PropertyCard
                          key={i.id}
                          code={i.code}
                          cta={i.status}
                          list1={i.property_address}
                          list2={i.property_address}
                          image={i.images[0]}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="PropertyCardGroup">
                    {this.state.found === 1 ? (
                      <div className="text-center">
                        <div
                          className="spinner-grow text-warning"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p>Loading. Please wait...</p>
                      </div>
                    ) : (
                      <p>No Property Found</p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(
  Dashboard
);
