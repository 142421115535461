import React from "react";
import "./Footer.css";
import axios from "axios";
import API_URL from "../../config/api";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isSubmitted: false,
      fade: false,
      message: "",
      success: false,
      email: "",
      error: ""
    };
  }

  handleEmail = (e) => {
    const email = e.target.value;
    this.setState({
      email
    });
  };

  subscribe = () => {
    axios
      .post(`${API_URL}/mailchimp-subscribe`, { email: this.state.email })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            success: true,
            isSubmitted: !this.state.isSubmitted,
            message: response.data.message
          });
        }
      })
      .catch((err) => {
        this.setState({
          success: false,
          isSubmitted: !this.state.isSubmitted,
          message: "Subscription failed, please try again."
        });
        setTimeout(() => {
          this.setState({
            isVisible: true,
            isSubmitted: false
          });
        }, 15000);
      });
  };

  render() {
    return (
      <>
        <div className="footer">
          <div className="footergroup">
            <div className="footer-sub-sec">
              <div className="footer-logo">
                <img
                  src={require("../../Images/exuity-logo.svg")}
                  className="img-fluid"
                  alt="Brand"
                />
              </div>
              <p>&copy; {new Date().getFullYear()}, Exuity</p>
            </div>
            <div className="footer-sub-sec">
              <li className="email-link">
                <Link to="/contact">
                  <svg
                    width="23"
                    height="17"
                    viewBox="0 0 23 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.4"
                      d="M18 6L13.0987 8.63572C12.1712 9.12143 10.8709 9.12143 9.94337 8.63572L5 6"
                      stroke="#4C506B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.31784 1H17.6463C18.8471 1.01347 19.9901 1.52113 20.8089 2.40475C21.6278 3.28837 22.0511 4.47082 21.9803 5.67671V11.4433C22.0511 12.6492 21.6278 13.8316 20.8089 14.7153C19.9901 15.5989 18.8471 16.1065 17.6463 16.12H5.31784C2.73846 16.12 1 14.0216 1 11.4433V5.67671C1 3.09842 2.73846 1 5.31784 1Z"
                      stroke="#4C506B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Contact Us
                </Link>
              </li>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
