import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import { registerUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./Form.css";
import PropertyCardSignup from "./PropertyCardSignup";
import { axios_registerUserByShiv } from "../utills/axios-routes";
import { axios_createInvestorProperty, axios_createOwnerProperty } from "../utills/property-routes";
import Head from "../Component/Head";
import { toast } from "react-toastify";

const SignUpForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const initialFormValue = {
    first_name: "",
    last_name: "",
    email: "",
    phoneno_country: "",
    phoneno: "",
    password: "",
    success: false
  };

  const [propertyAddress, setPropertyAddress] = useState();
  const [googlePlaceId, setGooglePlaceId] = useState();
  const [cardType, setCardType] = useState();
  const [searchTab, setSearchTab] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  const onChange4PhoneNo = (phoneno, countryCode, formik) => {
    formik.setFieldValue("phoneno", phoneno.slice(countryCode.dialCode.length));
    formik.setFieldValue("phoneno_country", countryCode.dialCode);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setPropertyAddress(queryParams.get("address"));
    setGooglePlaceId(queryParams.get("placeId"));
    setCardType(queryParams.get("cardType"));
    setSearchTab(queryParams.get("searchTab"));
    setLat(queryParams.get("lat"));
    setLng(queryParams.get("lng"));

    // console.log('getting all location da', cardType)
  }, [location]);

  const CreatePropertyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        toast.success("Successfully signed up.", { position: toast.POSITION.TOP_RIGHT });
        setTimeout(() => {
          history.push("/");
          toast.success(
            " An activation email is sent at your email address. Kindly Check and confirm your account.",
            { position: toast.POSITION.TOP_RIGHT }
          );
        }, 7000);
      }
    }
  };

  const registerUserCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        if (cardType === "Owner") {
          const OwnerPropertyData = {
            owner_id: response.data.data.id,
            property_address: propertyAddress,
            latitude: lat,
            longitude: lng,
            google_placeid: googlePlaceId,
            token: response.data.token
          };
          axios_createOwnerProperty(OwnerPropertyData, CreatePropertyCallback);
        } else if (cardType === "Investor") {
          const InvestorsPropertyData = {
            user_id: response.data.data.id,
            property_address: propertyAddress,
            latitude: lat,
            longitude: lng,
            google_placeid: googlePlaceId,
            token: response.data.token
          };
          axios_createInvestorProperty(InvestorsPropertyData, CreatePropertyCallback);
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValue}
      onSubmit={async (values, actions) => {
        let valuesData = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phoneno_country: values.phoneno_country,
          phoneno: values.phoneno,
          password: values.password,
          success: false
        };

        if (cardType) {
          axios_registerUserByShiv(valuesData, registerUserCallback);
        } else {
          if (valuesData.phoneno.length === 0) {
            dispatch(registerUser(valuesData));
          }
          if (valuesData.phoneno.length > 1) {
            dispatch(registerUser(valuesData));
          }
        }
      }}
      validationSchema={Yup.object({
        first_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your first name is required"),
        last_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your last name is required"),
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Email is required"),
        // phoneno: Yup.string()
        // 	.required("Phone Number is required"),
        // .matches(phoneRegExp, 'Phone Number is not valid')
        // .min(10, "Phone Number must have 10 digits")
        // .max(10, "Phone Number must have 10 digits"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      })}>
      {(formik, isSubmitting, setStatus, status) => {
        return (
          <Form className="form-wrapper">
            <Head title={"Exuity | Sign up"} />

            <div className="brand">
              <Link to="https://exuity.com">
                <img src={require("../Images/exuity-logo.svg")} alt="brand" />
              </Link>
            </div>
            {cardType && lat && lng && (
              <PropertyCardSignup
                lat={lat}
                lng={lng}
                address={propertyAddress}
                searchTab={searchTab}
                cardType={cardType}
              />
            )}
            {!formik.values.success ? (
              <div className="form-box signup-form-box">
                <div className="form-text-wrapper">
                  <h1>Begin Application to Use Exuity</h1>
                  <p>Join Exuity to move money in and out of real estate.</p>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                      name="first_name"
                      className={
                        formik.touched.first_name && formik.errors.first_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your First Name"
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">{formik.errors.first_name}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                      name="last_name"
                      className={
                        formik.touched.last_name && formik.errors.last_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your Last Name"
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">{formik.errors.last_name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <Field
                      name="email"
                      className={
                        formik.touched.email && formik.errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="email"
                      placeholder="Your Email Address"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneno">Phone Number</label>

                    <PhoneInput
                      country={"us"}
                      // value={this.state.phone}
                      onChange={(phoneno, countryCode) =>
                        onChange4PhoneNo(phoneno, countryCode, formik)
                      }
                      // disableCountryCode={true}
                      inputClass="PhoneInputInput form-control pl-5"
                      enableAreaCodes={true}
                      enableAreaCodeStretch
                    />

                    <div className="invalid-feedback d-block">
                      {formik.touched.phoneno && formik.errors.phoneno && formik.errors.phoneno}
                    </div>
                  </div>
                </div>

                <div className="form-group pass-input">
                  <label className="password-labels" htmlFor="password">
                    <span>Password</span>
                  </label>
                  <Field
                    name="password"
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Create Your Account Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button type="submit" className="blue-btn" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? "Please wait..." : "Join"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    Already a user? <Link to="/">Log In</Link>
                  </p>
                </div>
              </div>
            ) : (
              <div>
                An activation email is sent at your email address. Kindly Check and confirm your
                account.
              </div>
            )}
            <div className="copyrights-text">
              <p>&copy; {new Date().getFullYear()}, Exuity</p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
